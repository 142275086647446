import { findIndex } from 'lodash/fp';
function isRepeatingSequence(sequence) {
    const l = sequence.length;
    return l > 1 && sequence[l - 1] === sequence[l - 2];
}
export function findMatch(sequence, items, current) {
    const toMatch = match => ({ match, sequence });
    if (!sequence) {
        return toMatch(undefined);
    }
    const i = findIndex(txt => txt.indexOf(sequence) === 0, items);
    if (i !== -1) {
        return toMatch(items[i]);
    }
    const currentI = items.indexOf(current);
    if (currentI !== -1 && isRepeatingSequence(sequence)) {
        const slicedSequence = sequence.slice(0, sequence.length - 1);
        const nextItems = items.slice(currentI + 1);
        const next = findMatch(slicedSequence, nextItems, null);
        if (next.match !== undefined) {
            return next;
        }
        const prevItems = items.slice(0, currentI);
        return findMatch(slicedSequence, items, prevItems, null);
    }
    return toMatch(undefined);
}
