import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconSparkles } from 'featherico';
import { useEffect, useState } from 'react';
import ToolbarButton from '../../components/RichTextArea/Toolbar/button';
import SparkPosition from '../../components/Spark/SparkPosition';
import { trackEvent } from '../../services/Tracking';
import WritingAssistantModal from './lazy';
import styles from './style.m.less';
let sparkAlreadyShown = false;
export default function WritingAssistantToolbarButton({ getText, setText, context }) {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const [contentForPrompt, setContentForPrompt] = useState((_a = getText()) !== null && _a !== void 0 ? _a : '');
    const [showSpark, setShowSpark] = useState(false);
    const handleClick = () => {
        trackEvent({
            category: 'AI_WRITING_ASSISTANT',
            label: 'toolbar button',
            action: 'click',
        });
        setContentForPrompt(getText());
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (!sparkAlreadyShown) {
            sparkAlreadyShown = true;
            setShowSpark(true);
            return () => {
                sparkAlreadyShown = false;
            };
        }
    }, []);
    return (_jsxs(_Fragment, { children: [showSpark && (_jsx(SparkPosition, { x: -67, y: 12.5, zIndex: 2, story: "AI_ASSISTANT", type: "toolbarButton" })), _jsxs(ToolbarButton, { onClick: handleClick, title: "AI Writing Assistant", className: styles.button, children: [_jsx(IconSparkles, {}), " AI"] }), isOpen && (_jsx(WritingAssistantModal, { isOpen: isOpen, onClose: handleClose, content: contentForPrompt, setText: setText, context: context }))] }));
}
