import { jsx as _jsx } from "react/jsx-runtime";
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styles from './style.m.less';
const propTypes = {
    page: PropTypes.number.isRequired,
    children: PropTypes.any.isRequired,
    height: PropTypes.number.isRequired,
};
export default class SlideShow extends Component {
    render() {
        const { page, children, height } = this.props;
        return (_jsx("div", { className: styles.container, style: { minHeight: height }, children: _jsx("div", { className: styles.slider, style: {
                    transform: `translate3d(${-page * 100}%, 0, 0)`,
                }, children: renderItems(children) }) }));
    }
}
function renderItems(items) {
    return map(items, (component, i) => _jsx(Item, { children: component }, i));
}
function Item({ children }) {
    return _jsx("div", { className: styles.item, children: children });
}
SlideShow.propTypes = propTypes;
