import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Form as ReactFinalForm } from 'react-final-form';
import FormAutoBackup from './FormAutoBackup';
import FormAutoSave from './FormAutoSave';
import FormInterceptor from './FormInterceptor';
export const Form = (_a) => {
    var { children, autoSave, toBackup, intercept, autoComplete, formClassName, debounceTime } = _a, otherProps = __rest(_a, ["children", "autoSave", "toBackup", "intercept", "autoComplete", "formClassName", "debounceTime"]);
    return (_jsx(ReactFinalForm, Object.assign({}, otherProps, { children: (formRenderProps) => (_jsxs("form", { onSubmit: formRenderProps.handleSubmit, autoComplete: autoComplete, noValidate: true, className: formClassName, children: [!!autoSave && (_jsx(FormAutoSave, { keepDirtyOnReinitialize: !!otherProps.keepDirtyOnReinitialize, debounceTime: debounceTime })), !!toBackup && _jsx(FormAutoBackup, { toBackup: toBackup }), !!intercept && _jsx(FormInterceptor, { keepDirtyOnReinitialize: !!otherProps.keepDirtyOnReinitialize }), typeof children === 'function' ? children(formRenderProps) : children] })) })));
};
Form.propTypes = {
    initialValues: PropTypes.object,
    children: PropTypes.func.isRequired,
    validate: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    subscription: PropTypes.object,
};
