import { replace } from 'lodash/fp';
import api from '../../../../../api';
import AutocompletionBlot, { mkSerialize } from '../../helpers/autocompletion/blot';
class Mention extends AutocompletionBlot {
}
const users = {};
const storeUserOnSerialization = ({ value, serialization }) => (users[serialization] = value);
const deserializeBlot = serialization => {
    let value = users[serialization];
    if (!value) {
        // lookup users we don't know asynchronously - as the user keeps
        // typing we'll just automatically update the text content
        // UPDATE: this does not work anymore since the RTE is not a controlled component.
        // Mentions will only be resolved on subsequent RTE mounts.
        const loginname = serialization.slice(1); // remove leading @
        api.usersMedium.getByLoginname(loginname).then(user => {
            users[`@${user.loginname}`] = user.name;
        }, () => {
            // Since this is user generated content we handle errors silently
        });
        value = serialization;
    }
    return Mention.create({ value, serialization }).outerHTML;
};
// Eg. given "@foo@example.com @bar@example.com@kalle@example.com"
// it matches @foo@example.com, @bar@example.com and @kalle@example.com
// Note, the domain may not end with a special character (eg. å).
// This is accepted for now.
const matchSerializedBlot = /@[^\s@]+@[^\s@]+\.[^\s@<]+\w/g;
Mention.blotName = 'mention';
Mention.className = 'rte-mention';
Mention.siSerialize = mkSerialize(storeUserOnSerialization);
Mention.siDeserialize = replace(matchSerializedBlot, deserializeBlot);
export default Mention;
