import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconCheck, IconX } from 'featherico';
import { isFeatureEnabled } from '../../../services/Features';
import Button from '../../Button';
import { Card, CardHeader, CardHeaderButtons, CardHeaderHeading, CardSection, CardSectionBody } from '../../Cards';
import ButtonWithUpgradePlanModal from '../ButtonWithUpgradePlanModal';
import style from './styles.m.less';
export default function UpgradePlanPanel({ onDismiss }) {
    if (!isFeatureEnabled('surveys-basic-mode')) {
        return null; // Hide the spark if full surveys is already available
    }
    return (_jsxs(Card, { className: style.panelContainer, children: [_jsxs(CardHeader, { children: [_jsx(CardHeaderHeading, { children: "Elevate Your SI Experience" }), _jsx(CardHeaderButtons, { children: _jsx(Button, { onClick: onDismiss, kind: "PLAIN", className: style.dismissButton, children: _jsx(IconX, {}) }) })] }), _jsx(CardSection, { children: _jsxs(CardSectionBody, { children: [_jsx("p", { children: "Enhance your productivity now! \uD83D\uDE80" }), _jsxs("ul", { className: style.list, children: [_jsxs("li", { className: style.listItem, children: [_jsx(IconCheck, {}), "Advanced feedback tools and team engagement features"] }), _jsxs("li", { className: style.listItem, children: [_jsx(IconCheck, {}), "In-depth analytics and unlimited surveys"] }), _jsxs("li", { className: style.listItem, children: [_jsx(IconCheck, {}), "Full customization and enhanced integrations"] })] }), _jsx(ButtonWithUpgradePlanModal, { disabled: true, TriggerButton: () => _jsx(Button, { kind: "PRIMARY", children: "Upgrade Now" }), showUpgradeIcon: false })] }) })] }));
}
