import { jsx as _jsx } from "react/jsx-runtime";
import WritingAssistantToolbarButton from '../../../../features/WritingAssistant/WritingAssistantToolbarButton';
export const name = 'writingAssistant';
export function handler() { }
export const toolbarElements = [
    {
        key: 'ai',
        type: 'aiButton',
        component: ({ quill, config }) => {
            const getText = () => quill === null || quill === void 0 ? void 0 : quill.getText();
            const setText = (text) => quill.setText(text);
            return _jsx(WritingAssistantToolbarButton, { getText: getText, setText: setText, context: config.context });
        },
    },
];
