import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { DOWNLOADS } from '../../../../routes';
import { prependAppPath } from '../../../services/Url';
import AlertBox from '../../AlertBox';
import Link from '../../Link';
const DELAY_WARNING_MS = 8000;
export default class Waiting extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            isDelayed: false,
        };
    }
    componentDidMount() {
        this.timeoutFn = setTimeout(() => {
            this.setState({
                isDelayed: true,
            });
        }, DELAY_WARNING_MS);
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutFn);
    }
    render() {
        return (_jsxs("div", { children: [_jsx("h1", { children: "Hang tight\u2026" }), _jsx("p", { children: "We are crunching the data for you, please wait a moment." }), this.state.isDelayed && (_jsx(AlertBox, { colour: "BLUE", children: _jsxs("span", { children: [_jsx("b", { children: "This is taking longer than expected\u2026" }), " You don\u2019t have to wait here, though. We will add your export to the ", _jsx(Link, { href: prependAppPath(DOWNLOADS), children: "latest exports" }), " page as soon as it is finished."] }) }))] }));
    }
}
