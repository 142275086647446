// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InputIcon-inputIcon-2i90g {\n  position: absolute;\n  top: 2px;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  color: #4d4d4c;\n  pointer-events: none;\n}\n.form-group .InputIcon-inputIcon-2i90g {\n  right: 11px;\n}\n.InputIcon-default-1PBUd {\n  right: 11px;\n}\n.InputIcon-small-315-n {\n  right: 6px;\n}\n", ""]);
// Exports
exports.locals = {
	"inputIcon": "InputIcon-inputIcon-2i90g",
	"default": "InputIcon-default-1PBUd",
	"small": "InputIcon-small-315-n"
};
module.exports = exports;
