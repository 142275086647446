// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WritingAssistant-rewriteButton-1WNTM {\n  cursor: pointer;\n  width: 100%;\n}\n.WritingAssistant-rewriteButton-1WNTM,\n.WritingAssistant-count-3Pl5k,\n.WritingAssistant-rte-1K3_p {\n  margin-bottom: 20px;\n}\n.WritingAssistant-outputHeading-1Px1- {\n  margin-bottom: 10px;\n}\n.WritingAssistant-helpButton-3d6gs {\n  margin-left: 5px;\n}\n.WritingAssistant-buttonContainer-2_6V5 {\n  justify-content: space-between;\n  align-items: center;\n  height: 50px;\n}\n.WritingAssistant-buttonContainer-2_6V5 .WritingAssistant-button-3GgCd {\n  margin-top: 0;\n}\n.WritingAssistant-infoSection-1RVu3 {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.WritingAssistant-pillboxIcon-zsXWJ {\n  margin-right: 5px;\n}\n.WritingAssistant-sparkContent-3Rt4F {\n  font-family: 'TTNormsPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;\n  line-height: 1.5;\n}\n.WritingAssistant-sparkTitle-17r9m {\n  font-weight: 500 !important;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.WritingAssistant-sparkButton-1MdWS {\n  border: 1px solid transparent !important;\n  color: #000000 !important;\n  background: #ffd633 !important;\n  height: 28px !important;\n  width: auto !important;\n  padding: 5px 8px !important;\n}\n.WritingAssistant-sparkButton-1MdWS:active:not(.WritingAssistant-disabled-14Izw),\n.WritingAssistant-sparkButton-1MdWS:focus:not(.WritingAssistant-disabled-14Izw) {\n  border-color: #0071d4 !important;\n  box-shadow: 0 0 0 1px #0071d4 !important;\n}\n.WritingAssistant-sparkButton-1MdWS svg {\n  float: none !important;\n}\n.WritingAssistant-inlineCenterChild-2ETxK {\n  display: inline-flex;\n  vertical-align: baseline;\n  transform: translateY(calc((100% - 1cap) / 2));\n}\n.WritingAssistant-inlineCenterChild-2ETxK:before {\n  content: '';\n}\n", ""]);
// Exports
exports.locals = {
	"rewriteButton": "WritingAssistant-rewriteButton-1WNTM",
	"count": "WritingAssistant-count-3Pl5k",
	"rte": "WritingAssistant-rte-1K3_p",
	"outputHeading": "WritingAssistant-outputHeading-1Px1-",
	"helpButton": "WritingAssistant-helpButton-3d6gs",
	"buttonContainer": "WritingAssistant-buttonContainer-2_6V5",
	"button": "WritingAssistant-button-3GgCd",
	"infoSection": "WritingAssistant-infoSection-1RVu3",
	"pillboxIcon": "WritingAssistant-pillboxIcon-zsXWJ",
	"sparkContent": "WritingAssistant-sparkContent-3Rt4F",
	"sparkTitle": "WritingAssistant-sparkTitle-17r9m",
	"sparkButton": "WritingAssistant-sparkButton-1MdWS",
	"disabled": "WritingAssistant-disabled-14Izw",
	"inlineCenterChild": "WritingAssistant-inlineCenterChild-2ETxK"
};
module.exports = exports;
