// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Navigation-container-3I6nu {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 5px;\n  align-items: center;\n  padding: 0 5px;\n}\n.Navigation-arrow-3258k {\n  font-size: 15px;\n  font-weight: 500;\n  color: #a3a2a1;\n  --fi-size: 18px;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.Navigation-arrow-3258k:hover {\n  color: #4d4d4c;\n}\n.Navigation-arrow-3258k:disabled {\n  color: #edeceb;\n}\n.Navigation-arrowRight-3ztFy {\n  margin-left: 10px;\n}\n.Navigation-currentMonth-3h3Oz {\n  font-weight: 500;\n  font-size: 15px;\n  --fi-size: 18px;\n  --fi-stroke: var(--fi-stroke-m);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Navigation-container-3I6nu",
	"arrow": "Navigation-arrow-3258k",
	"arrowRight": "Navigation-arrowRight-3ztFy",
	"currentMonth": "Navigation-currentMonth-3h3Oz"
};
module.exports = exports;
