import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import { withAnimation } from 'react-with-animation';
import styles from './style.m.less';
const DEFAULT_FORM_ERROR_MESSAGE = 'Please check the fields that are marked above.';
const FormErrorMessagePlain = ({ errorMessage, className, style }) => (_jsx("div", { className: classNames(styles.errorMessage, className), style: style, "aria-label": "Error Message", children: errorMessage }));
export const FormErrorMessage = withAnimation(FormErrorMessagePlain);
FormErrorMessage.defaultProps = {
    errorMessage: DEFAULT_FORM_ERROR_MESSAGE,
    animationClasses: styles.errorMessageAnimation,
    animationDuration: 500,
    animateOnFirstRender: true,
};
