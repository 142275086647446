import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { FormSpy } from 'react-final-form';
import Interceptor from '../../Interceptor';
const DirtyInterceptor = ({ dirty, submitting, submitSucceeded, dirtySinceLastSubmit, keepDirtyOnReinitialize, }) => {
    const hasUnsavedChanges = keepDirtyOnReinitialize ? (!submitSucceeded && dirty) || dirtySinceLastSubmit : dirty;
    return _jsx(Interceptor, { if: hasUnsavedChanges || submitting });
};
const FormInterceptor = memo(({ keepDirtyOnReinitialize }) => (_jsx(FormSpy, { subscription: { dirty: true, submitting: true, submitSucceeded: true, dirtySinceLastSubmit: true }, component: renderProps => (_jsx(DirtyInterceptor, Object.assign({}, renderProps, { keepDirtyOnReinitialize: keepDirtyOnReinitialize }))) })));
export default FormInterceptor;
