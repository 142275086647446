import { jsx as _jsx } from "react/jsx-runtime";
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormSpy } from 'react-final-form';
import api from '../../../../api';
import { debounceWithOptions } from '../../../services/lodash++';
const DEBOUNCE_MAX_WAIT = 30000;
class AutoSaver extends Component {
    constructor() {
        super(...arguments);
        this.createBackup = () => this.props.toBackup(this.props.form, this.props.values);
        this.save = debounceWithOptions(DEBOUNCE_MAX_WAIT)({ maxWait: DEBOUNCE_MAX_WAIT })(async () => {
            if (!this.promise) {
                return;
            }
            const prevBackup = await this.promise;
            const nextBackup = this.createBackup();
            if (prevBackup && isEqual(prevBackup.texts, nextBackup.texts)) {
                return;
            }
            this.promise = api.backups.save(nextBackup).then(() => nextBackup, () => ({}));
        });
        this.render = () => null;
    }
    componentDidMount() {
        this.promise = Promise.resolve(this.createBackup());
    }
    componentDidUpdate() {
        this.save();
    }
    componentWillUnmount() {
        this.promise = undefined;
    }
}
export default function FormAutoBackup({ toBackup }) {
    return (_jsx(FormSpy, { subscription: { values: true }, children: renderProps => _jsx(AutoSaver, Object.assign({}, renderProps, { toBackup: toBackup })) }));
}
FormAutoBackup.propTypes = {
    toBackup: PropTypes.func.isRequired, // (form, values) => Backup
};
