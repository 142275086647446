import { curry, escape, filter, flow, map } from 'lodash';
import { getDirectReports, getManager, getSecondaryReports, getUrlToProfile, isManager, isTeamMember } from '../User';
import { cond } from './helper';
import { highlight, matchOn, wordMatches } from './search-helper';
export const TEAM = 'Team';
export const USER = 'Coworker';
export const SECONDARY = 'Secondary Reviewee';
const classifySelf = curry((currentUser, result) => {
    return Object.assign(Object.assign({}, result), { result: map(result.result, x => {
            if (x.data.id === currentUser.id) {
                x.typeFormatted = 'You';
                return x;
            }
            else {
                return x;
            }
        }) });
});
export function refineSearchResult(currentUser, managersReports, searchResult, nextResult) {
    const nextSearchResult = Object.assign({}, searchResult);
    nextSearchResult.result = searchResult.result.concat(classifyUsers(currentUser, managersReports, nextResult));
    const dataRefinement = flow(highlightQuery(nextSearchResult.query.trim()), withoutNotFound, withScore(currentUser, nextSearchResult.query.trim()), withUrl, withId, withTypeFormatted, classifySelf(currentUser), withDataType);
    return dataRefinement(nextSearchResult);
}
function classifyUsers(currentUser, managerReports, users) {
    if (isManager(currentUser)) {
        return map(users, cond({
            [TEAM]: isTeamMember(getDirectReports(currentUser)),
            [SECONDARY]: isTeamMember(getSecondaryReports(currentUser)),
        }, USER));
    }
    else {
        return map(users, cond({
            [TEAM]: isTeamMember(managerReports.concat(getManager(currentUser))),
            [SECONDARY]: isTeamMember(getSecondaryReports(currentUser)),
        }, USER));
    }
}
function withoutNotFound(result) {
    return Object.assign(Object.assign({}, result), { result: filter(result.result, x => {
            return x.data.role !== 'Try another search';
        }) });
}
function withScore(currentUser, query) {
    return result => (Object.assign(Object.assign({}, result), { result: usersWithScore(currentUser, result.result, query) }));
}
function usersWithScore(currentUser, users, query) {
    return map(users, addScore(currentUser, query));
}
function addScore(currentUser, query) {
    return user => {
        user.score = 0;
        if (matchOn('value', user, query)) {
            user.score += 0.1;
        }
        if (wordMatches('value', user, query)) {
            user.score += 0.2;
        }
        if (user.type === TEAM) {
            user.score += 0.1;
        }
        if (user.type === SECONDARY) {
            user.score += 0.09;
        }
        if (user.data.active) {
            user.score += 0.1;
        }
        if (user.data.id === currentUser.id) {
            user.score -= 0.1;
        }
        return user;
    };
}
function highlightQuery(query) {
    return result => (Object.assign(Object.assign({}, result), { result: map(result.result, addHighlightedStrings(query)) }));
}
function addHighlightedStrings(query) {
    return user => (Object.assign(Object.assign({}, user), { formattedValue: highlight(user.data.value, query), plainValue: user.data.value, formattedRole: highlight(escape(user.data.role), query), plainRole: user.data.role }));
}
function withUrl(result) {
    return Object.assign(Object.assign({}, result), { result: map(result.result, addUrl) });
}
function addUrl(user) {
    user.url = getUrlToProfile(user.data);
    return user;
}
function withId(result) {
    return Object.assign(Object.assign({}, result), { result: map(result.result, x => {
            x.id = x.data.id;
            return x;
        }) });
}
function withDataType(result) {
    return Object.assign(Object.assign({}, result), { result: map(result.result, x => {
            x.type = 'USER';
            return x;
        }) });
}
function withTypeFormatted(result) {
    return Object.assign(Object.assign({}, result), { result: map(result.result, x => {
            x.typeFormatted = x.type;
            return x;
        }) });
}
