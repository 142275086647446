// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddUpgradeIcon-container-1z9Eq {\n  position: relative;\n  color: #a3a2a1;\n  display: flex;\n}\n.AddUpgradeIcon-iconButton-3iLKa {\n  position: absolute;\n  margin: 0;\n  padding: 0;\n}\n.AddUpgradeIcon-iconButton-3iLKa:hover,\n.AddUpgradeIcon-iconButton-3iLKa:focus,\n.AddUpgradeIcon-iconButton-3iLKa:focus:not(.AddUpgradeIcon-disabled-2Qt7Z),\n.AddUpgradeIcon-icon-1RQsG:hover {\n  border-color: transparent !important;\n  box-shadow: none;\n}\n.AddUpgradeIcon-icon-1RQsG {\n  color: #ffd633;\n  margin-top: 2px;\n  width: auto !important;\n  font-size: 17px;\n  position: absolute;\n  fill: #ffd633;\n  cursor: pointer;\n  transition: all 0.2s ease-in-out;\n}\n.AddUpgradeIcon-container-1z9Eq:hover .AddUpgradeIcon-icon-1RQsG {\n  font-size: 20px;\n  transform: rotate(15deg);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "AddUpgradeIcon-container-1z9Eq",
	"iconButton": "AddUpgradeIcon-iconButton-3iLKa",
	"disabled": "AddUpgradeIcon-disabled-2Qt7Z",
	"icon": "AddUpgradeIcon-icon-1RQsG"
};
module.exports = exports;
