import { __rest } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { compose, first, isUndefined, last, omitBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component, createContext, createElement } from 'react';
import { mapProps } from '../../hoc/recompose';
import * as keyEventStackService from '../../services/KeyEventStack';
import { getNext, getPrevious } from '../../services/List';
const KEY_EVENTS = {
    arrowDown: 'ArrowDown',
    arrowUp: 'ArrowUp',
    tab: 'Tab',
    enter: 'Enter',
    escape: 'Escape',
};
const propTypes = {
    onOptionSelect: PropTypes.func,
    noDefaultButtonStyle: PropTypes.bool,
    leftAligned: PropTypes.bool,
};
const DropdownContext = createContext();
export const BEHAVIOR = {
    DROPDOWN: 'DROPDOWN',
    SELECT: 'SELECT',
};
export const createDropdown = ({ wrapperClassName, wrapperOpenClassName, menuComponent, triggerComponent, behavior, }) => {
    class Dropdown extends Component {
        constructor() {
            super();
            this.options = [];
            this.state = {
                isOpen: false,
                selectedOption: undefined,
            };
            this.handleDocumentClick = this.handleDocumentClick.bind(this);
            this.handleKeyEvent = this.handleKeyEvent.bind(this);
            this.handleMouseDown = this.handleMouseDown.bind(this);
            this.toggleDropdown = this.toggleDropdown.bind(this);
            this.onFocus = this.onFocus.bind(this);
            this.keyEventStackConfig = {
                onKey: this.handleKeyEvent,
            };
        }
        _addEventListeners() {
            document.addEventListener('click', this.handleDocumentClick);
            document.addEventListener('touchend', this.handleDocumentClick);
            keyEventStackService.register(this.keyEventStackConfig);
        }
        _removeEventListeners() {
            document.removeEventListener('click', this.handleDocumentClick);
            document.removeEventListener('touchend', this.handleDocumentClick);
            keyEventStackService.deregister(this.keyEventStackConfig);
        }
        registerOption(option) {
            this.options.push(option);
        }
        componentWillUnmount() {
            this._removeEventListeners();
        }
        handleDocumentClick(event) {
            if (this.ref && !this.ref.contains(event.target)) {
                if (this.state.isOpen) {
                    this.toggleDropdown(false);
                }
            }
        }
        handleMouseDown(event) {
            if (event.type === 'mousedown' && event.button !== 0) {
                return;
            }
            event.stopPropagation();
            this.toggleDropdown(!this.state.isOpen);
        }
        onFocus() {
            this.toggleDropdown(true);
            // give options time to register
            setTimeout(() => {
                if (this.options.length) {
                    this.selectOption(this.options[0]);
                }
            });
        }
        triggerOption() {
            if (this.state.selectedOption) {
                this.state.selectedOption.onClick();
            }
        }
        selectOption(option) {
            this.setState(() => ({
                selectedOption: option,
            }));
        }
        selectNext() {
            this.setState(oldState => ({
                selectedOption: getNext(this.options, oldState.selectedOption),
            }));
        }
        selectPrevious() {
            this.setState(oldState => ({
                selectedOption: getPrevious(this.options, oldState.selectedOption),
            }));
        }
        handleKeyEvent(event) {
            const actions = {
                [KEY_EVENTS.enter]: () => this.triggerOption(),
                [KEY_EVENTS.arrowUp]: () => this.selectPrevious(),
                [KEY_EVENTS.arrowDown]: () => this.selectNext(),
                [KEY_EVENTS.escape]: () => this.toggleDropdown(false),
            };
            if (behavior === BEHAVIOR.DROPDOWN) {
                actions[KEY_EVENTS.tab] = () => {
                    const { selectedOption } = this.state;
                    if (event.shiftKey) {
                        if (first(this.options) === selectedOption) {
                            this.toggleDropdown(false);
                            return; // propagate!
                        }
                        this.selectPrevious();
                    }
                    else {
                        if (last(this.options) === selectedOption) {
                            this.toggleDropdown(false);
                            return; // propagate!
                        }
                        this.selectNext();
                    }
                };
            }
            if (actions[event.key]) {
                actions[event.key]();
                event.stopPropagation();
                event.preventDefault();
                return false;
            }
        }
        toggleDropdown(nextIsOpen) {
            this.options = [];
            this.setState(() => {
                if (nextIsOpen) {
                    this._addEventListeners();
                }
                else {
                    this._removeEventListeners();
                }
                return {
                    isOpen: nextIsOpen,
                    selectedOption: undefined,
                };
            });
        }
        buildMenu() {
            return createElement(menuComponent, { large: this.props.large }, this.props.children);
        }
        render() {
            const { isOpen } = this.state;
            const { className, leftAligned } = this.props;
            const wrapperCn = classNames(className, wrapperClassName, {
                [wrapperOpenClassName]: this.state.isOpen,
                'left-aligned': leftAligned,
            });
            const context = {
                onClose: () => this.toggleDropdown(false),
                removeDocumentClick: () => this._removeEventListeners(),
                registerOption: option => this.registerOption(option),
                selectOption: option => this.selectOption(option),
                selectedOption: this.state.selectedOption,
            };
            return (_jsx(DropdownContext.Provider, { value: context, children: _jsxs("div", { className: wrapperCn, ref: ref => (this.ref = ref), children: [createElement(triggerComponent, Object.assign(Object.assign({}, this.props), { onClick: this.handleMouseDown, onFocus: this.onFocus, 'aria-expanded': isOpen })), isOpen ? this.buildMenu() : null] }) }));
        }
    }
    Dropdown.propTypes = propTypes;
    return Dropdown;
};
const withDropdownContext = BaseComponent => props => (_jsx(DropdownContext.Consumer, { children: context => _jsx(BaseComponent, Object.assign({}, props, context)) }));
const withCloseDropdown = compose(withDropdownContext, mapProps(props => {
    const { onClose, onClick } = props;
    return Object.assign(Object.assign({}, props), { onClick: (...args) => {
            onClose();
            return onClick(...args);
        }, onClose });
}));
const withRemoveDocumentClick = compose(withDropdownContext, mapProps(props => {
    const { removeDocumentClick, onClick } = props, rest = __rest(props, ["removeDocumentClick", "onClick"]);
    return Object.assign({ onClick: (...args) => {
            removeDocumentClick();
            return onClick(...args);
        } }, rest);
}));
let idCounter = 0;
export const createBaseOption = ({ activeClassName }) => comp => {
    return class BaseOption extends Component {
        UNSAFE_componentWillMount() {
            this.id = idCounter++;
            this.option = { id: this.id, onClick: this.props.onClick };
            this.props.registerOption(this.option);
        }
        render() {
            const { onClick, children, selectedOption, selectOption, className, href, eventTracking, disabled, openInNewTab, } = this.props;
            const cN = classNames(className, {
                [activeClassName]: selectedOption && selectedOption.id === this.id,
            });
            const elementProps = omitBy(isUndefined, { onClick, href, eventTracking, disabled, openInNewTab });
            return (_jsx("li", { className: cN, onMouseOver: () => selectOption(this.option), onMouseOut: () => selectOption(null), children: createElement(comp, elementProps, children) }));
        }
    };
};
export const composeOption = (...hocs) => compose(withCloseDropdown, ...hocs, withRemoveDocumentClick);
