import { jsx as _jsx } from "react/jsx-runtime";
import * as autocompletion from '../../helpers/autocompletion';
const NAME = 'madlibs';
const PLACEHOLDER = '________';
const TRIGGERS = [
    {
        value: '__?_?_?',
        type: 'regexp',
        choices: [
            {
                value: PLACEHOLDER,
                label: `Guided Text Placeholder ${PLACEHOLDER}`,
            },
        ],
    },
];
const STYLE = { minWidth: '250px' };
function Choice(props) {
    return (_jsx(autocompletion.ResultsItem, Object.assign({}, props, { style: STYLE, children: props.item.label })));
}
class Madlibs {
    constructor(quill) {
        const container = quill.addContainer('ql-madlibs');
        const instance = autocompletion.createInstance({
            quill,
            container,
            wrapperComponent: autocompletion.ResultsContainer,
            itemComponent: Choice,
            moreItemsComponent: autocompletion.ResultsMoreItems,
            maxItemsShown: 5,
            itemToKey: item => item.value,
            onSearch: query => Promise.resolve(query.trigger.choices),
            onSelect: (item, query) => {
                instance.hide();
                autocompletion.replaceQueryWithText(quill, query, item.value);
            },
        });
        instance.listen(TRIGGERS);
    }
}
export const name = NAME;
export const handler = Madlibs;
export const toolbarElements = [
    {
        key: 'madlibsPlaceholder',
        type: 'insertDropdown',
        onClick: ({ quill, getLastSelection }) => {
            const { index } = getLastSelection();
            const insertion = `${PLACEHOLDER} `;
            const nextIndex = index + insertion.length;
            quill.insertText(index, insertion, 'user');
            quill.setSelection(nextIndex);
        },
        label: _jsx("span", { children: "Guided Text Placeholder" }),
    },
];
