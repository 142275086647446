export function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
        return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
}
export function easeInCubic(t, b, c, d) {
    let tc = (t /= d) * t * t;
    return b + c * tc;
}
