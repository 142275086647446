// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PillBox-container-2lWjb {\n  display: inline-block;\n  padding: 1px 8px;\n  border-radius: 12px;\n  font-size: 13px;\n  font-weight: 400;\n  cursor: default;\n  --fi-size: 14px;\n  --fi-stroke: var(--fi-stroke-s);\n}\n.PillBox-hasOnClick-P2tH3 {\n  cursor: pointer;\n}\n.PillBox-defaultColor-3PW0F {\n  background-color: #e0dfde;\n  color: #1a1a1a;\n}\n.PillBox-teal-1JUiA {\n  background-color: #2eccc4;\n  color: white;\n}\n.PillBox-red-UAp6v {\n  background-color: #f23037;\n  color: white;\n}\n.PillBox-blue-QbqUW {\n  background-color: #0071d4;\n  color: white;\n}\n.PillBox-yellow-1r6Kc {\n  background-color: #ffcc00;\n  color: #1a1a1a;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "PillBox-container-2lWjb",
	"hasOnClick": "PillBox-hasOnClick-P2tH3",
	"defaultColor": "PillBox-defaultColor-3PW0F",
	"teal": "PillBox-teal-1JUiA",
	"red": "PillBox-red-UAp6v",
	"blue": "PillBox-blue-QbqUW",
	"yellow": "PillBox-yellow-1r6Kc"
};
module.exports = exports;
