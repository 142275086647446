import { findIndex } from 'lodash/fp';
export function parseContent(content, index, triggers) {
    const before = content.slice(0, index);
    const { triggerPos, trigger } = findLastTrigger(before, triggers);
    if (triggerPos === -1) {
        return;
    }
    // When there is whitespace between the trigger and the cursor,
    // the user is just writing other text.
    const inBetween = content.slice(triggerPos, index);
    const inBetweenI = findWhitespaceLikeIndex(inBetween);
    if (inBetweenI !== -1) {
        return;
    }
    const afterTrigger = content.slice(triggerPos);
    const i = findWhitespaceLikeIndex(afterTrigger);
    const start = triggerPos;
    const end = i === -1 ? index : start + i;
    const searchable = content.slice(triggerPos, end);
    return {
        string: searchable,
        start,
        end,
        index: start,
        length: end - start,
        trigger,
    };
}
const TRIGGER_FINDERS = {
    string(trigger, string) {
        return string.lastIndexOf(trigger.value);
    },
    regexp(trigger, string) {
        const { value, modifiers } = trigger;
        return string.search(new RegExp(value + '$', modifiers));
    },
};
function findLastTrigger(string, triggers) {
    var trigger, finder, rawIndex, index = -1;
    for (var i = 0; i < triggers.length; i++) {
        trigger = triggers[i];
        finder = TRIGGER_FINDERS[trigger.type];
        rawIndex = finder(trigger, string);
        index = getValidatedTriggerPos(rawIndex, string);
        if (index !== -1) {
            break;
        }
    }
    return { triggerPos: index, trigger };
}
// Only valid when it's at the beginning of a string
// or after whitespace or parenthesis.
function getValidatedTriggerPos(i, string) {
    if (i === -1) {
        return i;
    }
    return i === 0 || string[i - 1].match(/[\s([]/) ? i : -1;
}
function findWhitespaceLikeIndex(str) {
    return findIndex(chr => chr.match(/\s/), str);
}
