import moment from 'moment';
export default function getIsDateInRangeError(date, validRange, dateFormat) {
    const tooEarly = !!validRange.from && date.isBefore(validRange.from);
    const tooLate = !!validRange.to && date.isAfter(validRange.to);
    if (tooEarly || tooLate) {
        const phraseParts = [];
        if (!!validRange.from) {
            phraseParts.push(`after ${moment(validRange.from).format(dateFormat)}`);
        }
        if (!!validRange.to) {
            phraseParts.push(`before ${moment(validRange.to).format(dateFormat)}`);
        }
        const rangePhrase = phraseParts.join(' and ');
        return `Please make sure the date is ${rangePhrase}`;
    }
}
