// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormInputs-formInputWrapper-nYmRc {\n  margin-bottom: 30px;\n}\n.grid-item > .FormInputs-formInputWrapper-nYmRc {\n  margin-bottom: 10px;\n}\n.FormInputs-formInput-2HcOq input {\n  padding: 9.5px;\n}\n.FormInputs-formCheckbox-2fE39 {\n  display: block;\n}\n.FormInputs-formRichTextArea-2X-pg .ql-editor {\n  padding: 9.5px;\n}\n.FormInputs-formTextareaAutosize-1Iz1g {\n  padding: 9.5px;\n}\n.FormInputs-formButtonContainer-1oTt6 {\n  flex-direction: row-reverse;\n  justify-content: flex-start;\n  flex-wrap: nowrap;\n  align-items: center;\n}\n.FormInputs-formButtonContainer-1oTt6 .button {\n  margin-top: 0;\n}\n.FormInputs-userTagContainer-22ENV {\n  margin: 6px 0;\n}\n.FormInputs-formSentence-3HOWm {\n  display: flex;\n  align-items: center;\n}\n.FormInputs-formSentence-3HOWm:not(:last-child) {\n  margin-bottom: 10px;\n}\n.FormInputs-formSentenceInput-cwluN {\n  margin-left: 5px;\n  margin-right: 5px;\n}\n.FormInputs-formSentenceInput-cwluN > input {\n  width: 60px;\n  padding: 9.5px;\n}\n.FormInputs-formSentenceInput-cwluN > button {\n  width: 60px;\n}\n.FormInputs-selectSimple-1e42r {\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"formInputWrapper": "FormInputs-formInputWrapper-nYmRc",
	"formInput": "FormInputs-formInput-2HcOq",
	"formCheckbox": "FormInputs-formCheckbox-2fE39",
	"formRichTextArea": "FormInputs-formRichTextArea-2X-pg",
	"formTextareaAutosize": "FormInputs-formTextareaAutosize-1Iz1g",
	"formButtonContainer": "FormInputs-formButtonContainer-1oTt6",
	"userTagContainer": "FormInputs-userTagContainer-22ENV",
	"formSentence": "FormInputs-formSentence-3HOWm",
	"formSentenceInput": "FormInputs-formSentenceInput-cwluN",
	"selectSimple": "FormInputs-selectSimple-1e42r"
};
module.exports = exports;
