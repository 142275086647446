// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatPromotion-illustration-3LRer {\n  width: 100%;\n}\n.ChatPromotion-icon-2LLX5 {\n  height: 1em;\n  position: relative;\n  top: 2px;\n  margin: 0 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"illustration": "ChatPromotion-illustration-3LRer",
	"icon": "ChatPromotion-icon-2LLX5"
};
module.exports = exports;
