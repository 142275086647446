import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
import Spark from '..';
export default function SparkPosition({ x = 0, y = 0, zIndex = 3, children, story, type, direction }) {
    const style = {
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        width: '20px',
    };
    return (_jsx("span", { style: { position: 'relative', zIndex }, children: _jsx("span", { style: style, children: story && type ? _jsx(Spark, { story: story, type: type, direction: direction }) : _jsx("span", { children: children }) }) }));
}
SparkPosition.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    direction: PropTypes.string,
    type: PropTypes.string,
    story: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
