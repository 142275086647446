// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ImageWithAspectRatio-image-G1xmY {\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n", ""]);
// Exports
exports.locals = {
	"image": "ImageWithAspectRatio-image-G1xmY"
};
module.exports = exports;
