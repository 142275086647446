const TRIGGERS = {
    leftArrow: {
        name: 'leftArrow',
        value: '->',
        insert: '→',
        allowWithinText: true,
    },
    rightArrow: {
        name: 'rightArrow',
        value: '<-',
        insert: '←',
        allowWithinText: true,
    },
    doubleArrow: {
        name: 'doubleArrow',
        value: '<->',
        insert: '↔',
        allowWithinText: true,
    },
};
class AutoConversions {
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        quill.on('text-change', this.check.bind(this));
    }
    check(delta, oldDelta, source) {
        if (source !== 'user') {
            return;
        }
        const { ops } = delta;
        if (ops.length > 2) {
            return;
        }
        if (this.modeEntered) {
            this.tryModeReversion(ops);
        }
        this.modeEntered = null;
        if (ops.length !== 2) {
            return;
        }
        this.tryAutoConversion(ops);
    }
    tryModeReversion([operation1, operation2]) {
        if (this.isBackspaceOperation(operation1, operation2)) {
            const trigger = TRIGGERS[this.modeEntered];
            const insertion = trigger.value + ' ';
            const { retain } = operation1;
            let pos = (operation2 || {}).retain === 1 ? retain : retain - 1;
            if (trigger.insert) {
                const l = trigger.insert.length; // + 1 for whitespace
                pos = pos - l;
                this.quill.deleteText(pos, l + 1);
            }
            this.quill.insertText(pos, insertion);
            // give editor time to update before moving the cursor
            setTimeout(() => this.quill.setSelection(pos + insertion.length), 0);
        }
    }
    tryAutoConversion([operation1, operation2]) {
        const { retain: position } = operation1;
        if (!this.isWhiteSpaceInsertionOperation(operation2)) {
            return;
        }
        const keys = Object.keys(TRIGGERS);
        for (let i = keys.length - 1; i >= 0; i--) {
            let key = keys[i];
            if (TRIGGERS[key]) {
                const trigger = TRIGGERS[key];
                if (this.isTriggering(trigger.value, position)) {
                    if (trigger.mode) {
                        this.enterMode(trigger, position);
                    }
                    else {
                        this.autoConvert(trigger, position);
                    }
                    return;
                }
            }
        }
    }
    isTriggering(triggerVal, position) {
        const text = this.quill.getText(position - triggerVal.length, triggerVal.length);
        return text === triggerVal;
    }
    enterMode(trigger, position) {
        const { name, mode, value } = trigger;
        this.quill.deleteText(position - value.length, value.length);
        this.quill.format(mode, true);
        this.modeEntered = name;
    }
    autoConvert(trigger, position) {
        const { name, insert, value } = trigger;
        const prevPosition = position - value.length;
        this.quill.deleteText(prevPosition, value.length + 1); // + 1 for whitespace
        this.quill.insertText(prevPosition, insert + '  ');
        this.modeEntered = name;
    }
    isWhiteSpaceInsertionOperation(operation) {
        const { insert } = operation;
        return insert && insert.match(/^ +$/);
    }
    isBackspaceOperation(operation1, operation2) {
        return operation1.retain === 1 || (operation2 && (operation2.delete === 1 || operation2.retain === 1));
    }
}
export const name = 'autoConversions';
export const handler = AutoConversions;
