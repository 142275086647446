import { pull } from 'lodash';
import history from './history';
/**
 * This simply wraps `history.block`, accounting for test runs where `history` might be undefined
 */
function historyBlock(prompt) {
    if (history) {
        return history.block(prompt);
    }
    else {
        return () => undefined;
    }
}
const blockers = [];
let unblockHistory = null;
export function block(shouldBlock) {
    blockers.push(shouldBlock);
    if (!unblockHistory) {
        unblockHistory = historyBlock(promptHook);
        window.addEventListener('beforeunload', handleUnload);
    }
    return function unblock() {
        pull(blockers, shouldBlock);
        // @ts-ignore We're not using this value again. Nullifying is a safeguard against memleaks
        shouldBlock = null;
        if (blockers.length === 0) {
            unblockHistory();
            unblockHistory = null;
            window.removeEventListener('beforeunload', handleUnload);
        }
    };
}
function promptHook(newLocation) {
    const currentLocation = history === null || history === void 0 ? void 0 : history.location;
    const blocking = blockers.reduce((result, shouldBlock) => result || shouldBlock(currentLocation, newLocation), false);
    if (blocking === true) {
        return 'Any unsaved changes will be lost. Are you sure you want to leave this page?';
    }
    else if (blocking === false) {
        return undefined;
    }
    else {
        return blocking;
    }
}
function handleUnload(event) {
    const result = promptHook();
    if (result === false || typeof result === 'string') {
        // Note: The actual text here doesn't matter. Browsers will render a fixed text for the beforeUnload event
        event.returnValue = result;
        return result;
    }
}
