// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Popup-container-3vFKS {\n  border: 1px solid #e0dfde;\n  border-radius: 4px;\n  background-color: white;\n  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n  font-weight: 400;\n  padding: 10px;\n  --fi-stroke: var(--fi-stroke-s);\n}\n.Popup-summary-1ziyj {\n  font-size: 13px;\n  color: #a3a2a1;\n  text-align: center;\n  max-width: 200px;\n  --fi-size: 14px;\n}\n.Popup-error-1nAIv {\n  color: #f23037;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Popup-container-3vFKS",
	"summary": "Popup-summary-1ziyj",
	"error": "Popup-error-1nAIv"
};
module.exports = exports;
