import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import Spinner from '../Spinner';
export default function Loader(_a) {
    var { className } = _a, other = __rest(_a, ["className"]);
    const props = Object.assign(Object.assign({}, other), { className: classNames(className, 'react-loader') });
    return (_jsx("div", Object.assign({}, props, { children: _jsx(Spinner, {}) })));
}
