import Quill from 'quill';
const Link = Quill.import('formats/link');
class NoopenerLink extends Link {
    static create(value) {
        const node = super.create(value);
        node.setAttribute('rel', 'noopener noreferrer');
        return node;
    }
}
Quill.debug('error');
Quill.register(NoopenerLink);
Quill.debug('warn');
