import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forEach, includes, map, omit, reject, some } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import visibleInBody from '../../../common/services/visible-in-body';
const DIRECTION_CLASSES = [
    'top',
    'right',
    'bottom',
    'left',
    'top--left',
    'top--right',
    'bottom--left',
    'bottom--right',
];
const DEFAULT_DIRECTION = 'right';
function getDirectionClass(dirClass) {
    return includes(DIRECTION_CLASSES, dirClass) ? dirClass : DEFAULT_DIRECTION;
}
function mapClassNames(direction) {
    return ['ui-popover-react', direction, 'callout', `callout--${direction}`];
}
function removeClassName(node) {
    return function (classes) {
        node.classList.remove(...classes);
    };
}
function addClassAndCheck(node) {
    return function (classes, index, classnames) {
        forEach(classnames, removeClassName(node));
        node.classList.add(...classes);
        return visibleInBody(node.getBoundingClientRect());
    };
}
function position(dirClass, node) {
    const preferredDirection = getDirectionClass(dirClass);
    const otherDirections = reject(DIRECTION_CLASSES, val => val === preferredDirection);
    const orderedDirections = [preferredDirection, ...otherDirections, preferredDirection];
    const classes = map(orderedDirections, mapClassNames);
    some(classes, addClassAndCheck(node));
}
class UiPopover extends Component {
    constructor(props) {
        super(props);
        this.checkFocus = this.checkFocus.bind(this);
    }
    componentDidMount() {
        position(this.props.direction, this.node);
        if (!this.props.onFocusLost) {
            return;
        }
        setTimeout(() => {
            window.addEventListener('click', this.checkFocus);
        });
    }
    componentWillUnmount() {
        if (!this.props.onFocusLost) {
            return;
        }
        window.removeEventListener('click', this.checkFocus);
    }
    checkFocus(event) {
        if (!this.node.contains(event.target)) {
            this.props.onFocusLost();
        }
    }
    render() {
        let _a = this.props, { calloutClass, children, direction } = _a, others = __rest(_a, ["calloutClass", "children", "direction"]);
        let classes = 'ui-popover-react callout callout--' + direction;
        return (_jsx("div", { className: classNames(classes, direction), ref: n => (this.node = n), children: _jsx("div", Object.assign({ className: classNames('callout-content', calloutClass) }, omit(others, ['onFocusLost']), { children: children })) }));
    }
}
UiPopover.propTypes = {
    children: PropTypes.object,
    direction: PropTypes.string,
};
export default UiPopover;
