// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderSpark-container-1bd__ {\n  display: flex;\n  background: #ffd633;\n  font-size: 13px;\n  padding: 10px;\n  --fi-size: 14px;\n}\n.HeaderSpark-container-1bd__ a {\n  color: #1a1a1a;\n  text-decoration: underline;\n}\n.HeaderSpark-container-1bd__ + .HeaderSpark-container-1bd__ {\n  border-top: 1px solid #ffffff;\n}\n.HeaderSpark-message-W05Np {\n  flex: 1;\n  text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "HeaderSpark-container-1bd__",
	"message": "HeaderSpark-message-W05Np"
};
module.exports = exports;
