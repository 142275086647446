// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ql-toolbar.ql-snow {\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n}\n.ql-toolbar.ql-snow .ql-formats.right {\n  float: right;\n  margin-right: 0;\n  width: 48px;\n}\n.ql-toolbar.ql-snow .ql-formats.right > button {\n  width: 48px;\n}\n.ql-toolbar.ql-snow .ql-formats > button {\n  width: 24px;\n  margin-right: 4px;\n  font-weight: 500;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.ql-toolbar.ql-snow .ql-formats > button,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button,\n.ql-toolbar.ql-snow .ql-formats > button:hover,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button:hover {\n  padding: 3px;\n  border-radius: 4px;\n  transition: none;\n  color: #7a7a79;\n}\n.ql-toolbar.ql-snow .ql-formats > button .ql-stroke,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button .ql-stroke,\n.ql-toolbar.ql-snow .ql-formats > button:hover .ql-stroke,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button:hover .ql-stroke {\n  stroke: #7a7a79;\n}\n.ql-toolbar.ql-snow .ql-formats > button .ql-fill,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button .ql-fill,\n.ql-toolbar.ql-snow .ql-formats > button:hover .ql-fill,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button:hover .ql-fill {\n  fill: #7a7a79;\n}\n.ql-toolbar.ql-snow .ql-formats > button svg,\n.ql-toolbar.ql-snow .ql-formats .ui-dropdown > button svg {\n  float: none;\n}\n.ql-toolbar.ql-snow .ql-formats:empty {\n  display: none;\n}\n.ql-toolbar.ql-snow .ui-dropdown > button,\n.ql-toolbar.ql-snow .ui-dropdown .Toolbar-dropdownButton-2CAib {\n  width: auto;\n  float: none;\n  border: none;\n}\n.ql-toolbar.ql-snow .ui-dropdown .ui-dropdown-drop button {\n  width: auto;\n  height: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"dropdownButton": "Toolbar-dropdownButton-2CAib"
};
module.exports = exports;
