// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal-optionContainer-2z886 {\n  display: flex;\n  flex-wrap: wrap;\n}\n.Modal-option-3MuD4 {\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: 200px;\n  min-width: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 40px;\n}\n.Modal-logoContainer-VAxjZ {\n  width: 70%;\n}\n.Modal-logo-3I6xc {\n  padding-bottom: 50%;\n  background-size: 100% auto;\n  background-repeat: no-repeat;\n}\n.Modal-screenshot-nlzcr {\n  max-width: 100%;\n  border: 1px solid #bdbdbd;\n  border-radius: 4px;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);\n  transform: translateZ(1px);\n  background: #ffffff;\n}\n", ""]);
// Exports
exports.locals = {
	"optionContainer": "Modal-optionContainer-2z886",
	"option": "Modal-option-3MuD4",
	"logoContainer": "Modal-logoContainer-VAxjZ",
	"logo": "Modal-logo-3I6xc",
	"screenshot": "Modal-screenshot-nlzcr"
};
module.exports = exports;
