import { camelCase, kebabCase, reduce, toPairs } from 'lodash/fp';
import PERSONALIZATION_REPLACEMENTS from '../../../../constants/personalization-replacements';
const toRegExpTrigger = trigger => (Object.assign(Object.assign({}, trigger), { type: 'regexp', modifiers: 'i' }));
export const REPLACEMENTS = reduce(reduceWithWrappers, {}, toPairs({
    subjectPronoun: {
        replacement: PERSONALIZATION_REPLACEMENTS.SUBJECT_PRONOUN,
        value: 'he/she/they',
        label: 'Subject Pronoun (he/she/they)',
    },
    objectPronoun: {
        replacement: PERSONALIZATION_REPLACEMENTS.OBJECT_PRONOUN,
        value: 'him/her/them',
        label: 'Object Pronoun (him/her/them)',
    },
    possPronoun: {
        replacement: PERSONALIZATION_REPLACEMENTS.POSSESSIVE_PRONOUN,
        value: 'his/her/their',
        label: 'Possessive Pronoun (his/her/their)',
    },
    reflPronoun: {
        replacement: PERSONALIZATION_REPLACEMENTS.REFLEXIVE_PRONOUN,
        value: 'himself/herself/themself',
        label: 'Reflexive Pronoun (himself/herself/themself)',
    },
    firstName: {
        replacement: PERSONALIZATION_REPLACEMENTS.FIRST_NAME,
        value: 'First Name',
        label: 'First name',
    },
    lastName: {
        replacement: PERSONALIZATION_REPLACEMENTS.LAST_NAME,
        value: 'Last Name',
        label: 'Last name',
    },
    fullName: {
        replacement: PERSONALIZATION_REPLACEMENTS.FULL_NAME,
        value: 'Full Name',
        label: 'Full name',
    },
}));
function reduceWithWrappers(mem, [key, item]) {
    mem[key] = addWrappers(item);
    return mem;
}
function addWrappers(item) {
    item.replacement = createReplacement(item.replacement);
    return item;
}
function createReplacement(str) {
    return '{{ ' + str + ' }}';
}
function invertReplacement(replacement) {
    return replacement.replace(/({{\s?)(.+?)(\s?}})/, (m, start, key, end) => {
        // herHim to himHer
        const invertedKey = camelCase(kebabCase(key).split('-').reverse());
        return `${start}${invertedKey}${end}`;
    });
}
export function matchesReplacement(replacement, other) {
    return replacement === other || replacement === invertReplacement(other);
}
export const TRIGGERS = [
    {
        value: '(she|he)',
        choices: [REPLACEMENTS.subjectPronoun],
    },
    {
        value: '(his|their)',
        choices: [REPLACEMENTS.possPronoun],
    },
    {
        value: '(him|them)',
        choices: [REPLACEMENTS.objectPronoun],
    },
    {
        value: '(him|her|them)se?l?f?',
        choices: [REPLACEMENTS.reflPronoun],
    },
    {
        value: 'her',
        choices: [REPLACEMENTS.possPronoun, REPLACEMENTS.objectPronoun],
    },
    {
        value: '(firs?t?|last?|name?)',
        choices: [REPLACEMENTS.firstName, REPLACEMENTS.lastName, REPLACEMENTS.fullName],
    },
].map(toRegExpTrigger);
export const ALL_CHOICES = [
    REPLACEMENTS.firstName,
    REPLACEMENTS.lastName,
    REPLACEMENTS.fullName,
    REPLACEMENTS.subjectPronoun,
    REPLACEMENTS.possPronoun,
    REPLACEMENTS.objectPronoun,
    REPLACEMENTS.reflPronoun,
];
