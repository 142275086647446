class SubmitThroughKeyboardHint {
    constructor() {
        // This plugin is automatically included when we pass the `onSubmitThroughKeyboard` prop
        // to our RichTextArea.
        // The original idea was to pass a clickhandler to the plugin and handle it here,
        // which is not possible for reasons discussed in https://github.com/zenoamaro/react-quill/issues/237
        // The `onSubmitThroughKeyboard` solution is a workaround to this probem.
        //
        // Add a container here which somehow tells people that this is possible - Lucas tried to come up
        // with a design for this, but this might be on hold due to Charlie!
    }
}
export const name = 'submitThroughKeyboardHint';
export const handler = SubmitThroughKeyboardHint;
