import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { useState } from 'react';
import { CustomerState } from '../../../../../types/CustomerDto';
import { ADMIN_BILLING_CHECKOUT } from '../../../../routes';
import LinkButton from '../../../components/LinkButton';
import Modal from '../../../components/Modal';
import { ModalTitle } from '../../../components/Modal/presenter';
import { useAppContext } from '../../../hooks/useAppContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { interpolateAppUrl } from '../../../services/Url';
import HeaderSpark from '../../HeaderSpark';
import { sumBy } from '../services/plan';
import useCustomer from '../useCustomer';
export default function PromptToCheckout(props) {
    const { user } = useAppContext();
    if (!user.secureLogin) {
        return null;
    }
    return _jsx(PromptToCheckoutInner, Object.assign({}, props));
}
function PromptToCheckoutInner({ onDismiss, onClose }) {
    const customer = useCustomer();
    const [dismissedAt, setDismissedAt] = useLocalStorage('dismissedTrialCheckoutReminder', null);
    const [hidden, setHidden] = useState(false);
    if (!customer)
        return null;
    if (hidden)
        return null;
    if (sumBy(customer.priceInfo, 'monthlyWithNonProfitAndDiscount') === 0) {
        // once this spark is hidden because the price === 0, never show it again before the page is reloaded.
        // we persist this decision in the state to avoid that the modal pops up when you
        // enter a license count in the checkout screen
        setHidden(true);
        return null;
    }
    if (customer.state === CustomerState.FUTURE) {
        if (deadlineIsNear(customer.subscription.startDate, 7)) {
            return _jsx(PromptModal, { onClose: onClose, onCheckout: onClose });
        }
        else {
            return _jsx(PromptBanner, {});
        }
    }
    else if (customer.state === CustomerState.TRIAL) {
        if (deadlineIsNear(customer.subscription.trialEnd, 7)) {
            if (dismissedAt && moment(dismissedAt).isAfter(moment().subtract(1, 'day'))) {
                return null;
            }
            const handleDismiss = () => {
                if (onDismiss) {
                    setDismissedAt(Date.now());
                    onDismiss();
                }
            };
            return _jsx(PromptBanner, { onClickX: handleDismiss, onClickLink: onClose });
        }
    }
    return null;
}
function PromptModal(props) {
    return (_jsxs(Modal, { isOpen: true, onClose: props.onClose, children: [_jsx(ModalTitle, { children: "Please complete your checkout" }), _jsx("p", { children: _jsx(Text, { onClick: props.onCheckout }) }), _jsx(LinkButton, { kind: "PRIMARY", href: interpolateAppUrl(ADMIN_BILLING_CHECKOUT, {}), onClick: props.onCheckout, children: "Check out now" })] }));
}
function PromptBanner(props) {
    return (_jsxs(HeaderSpark, { onDone: props.onClickX, children: [_jsx("strong", { children: "Please complete your checkout:" }), " ", _jsx(Text, { onClick: props.onClickLink })] }));
}
function Text(props) {
    return (_jsxs(_Fragment, { children: ["Your subscription is starting soon, but we do not have your payment details on file. Please", ' ', _jsx("a", { onClick: props.onClick, href: interpolateAppUrl(ADMIN_BILLING_CHECKOUT, {}), children: "visit the check-out page" }), ' ', "and enter your payment details to activate your subscription."] }));
}
function deadlineIsNear(deadline, days) {
    const near = moment().add(days, 'days');
    return moment(deadline).isSameOrBefore(near);
}
