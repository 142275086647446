// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormLabel-label-3GhCT {\n  display: inline-block;\n  margin-bottom: 5px;\n  color: #4d4d4c;\n  font-size: 13px;\n  --fi-size: 14px;\n}\n.FormLabel-subLabel-1MyQZ {\n  font-size: 13px;\n  color: #a3a2a1;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"label": "FormLabel-label-3GhCT",
	"subLabel": "FormLabel-subLabel-1MyQZ"
};
module.exports = exports;
