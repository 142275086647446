import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
const options = {
    half: '2',
    third: '3',
    quarter: '4',
    fit: 'fit',
    full: 'full',
    hug: 'hug',
};
const screenResolutionPrefixes = {
    xs: 'xs-',
    s: 's-',
    m: 'm-',
    l: 'l-',
    desktop: '',
};
GridItem.propTypes = {
    xs: PropTypes.oneOf(Object.keys(options)),
    s: PropTypes.oneOf(Object.keys(options)),
    m: PropTypes.oneOf(Object.keys(options)),
    l: PropTypes.oneOf(Object.keys(options)),
    desktop: PropTypes.oneOf(Object.keys(options)),
};
export function GridItem(_a) {
    var { children } = _a, resolutions = __rest(_a, ["children"]);
    const classes = Object.entries(resolutions)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_resolution, size]) => !!size)
        .map(([resolution, size]) => `${screenResolutionPrefixes[resolution]}cols--${options[size]}`);
    return _jsx("div", { className: classNames('grid-item', classes), children: children });
}
