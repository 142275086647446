import PromptToCheckout from '../../features/Billing/components/PromptToCheckout';
import ChatPromotion from '../../features/ChatPromotion';
import MeetingActionSpark from '../../features/Meetings/Meeting/ActionItems/MeetingActionSpark';
import CalendarSettingsAlert from '../../features/Meetings/MeetingOverviewPage/CalendarSettings/Alert';
import { WritingAssistantSparkContent } from '../../features/WritingAssistant/WritingAssistantSparkContent';
import UpgradePlanPanel from '../UpgradePlan/UpgradePlanPanel';
/* eslint-disable max-len */
export var SparkVisibility;
(function (SparkVisibility) {
    /** Only show to first-time users */
    SparkVisibility["INTRODUCTION"] = "INTRODUCTION";
    /** Show to every user */
    SparkVisibility["ANNOUNCEMENT"] = "ANNOUNCEMENT";
    /** Only show to users who are using a page/feature “frequently” */
    SparkVisibility["PRO_TIP"] = "PRO_TIP";
})(SparkVisibility || (SparkVisibility = {}));
export var ComponentType;
(function (ComponentType) {
    ComponentType["POPOVER"] = "popover";
    ComponentType["MODAL"] = "modal";
    ComponentType["MULTI_MODAL"] = "multiModal";
    ComponentType["INLINE"] = "inline";
})(ComponentType || (ComponentType = {}));
export var BodyType;
(function (BodyType) {
    BodyType["TEXT"] = "text";
    BodyType["VIDEO"] = "video";
    BodyType["IMAGE"] = "image";
    BodyType["COMPONENT"] = "component";
})(BodyType || (BodyType = {}));
const SPARK_CONTENT = {
    TABLE: {
        magicWand: {
            title: 'Magic Column',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.PRO_TIP,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'Whenever you see the magic wand, you can change the column to filter by a different category.',
                    },
                },
            ],
        },
        multiSelect: {
            title: 'Multi Select',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.PRO_TIP,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'Looking to perform an action on multiple users? You can select several users by holding shift and clicking.',
                    },
                },
            ],
        },
    },
    COMPANY_OVERVIEW: {
        notify: {
            title: 'Notify Everyone',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.PRO_TIP,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'Use the dropdown to nudge users in bulk.',
                    },
                },
            ],
        },
        chart: {
            title: 'Filtering Made Easy',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.PRO_TIP,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'Click a slice of the chart to filter the table below.',
                    },
                },
            ],
        },
    },
    CYCLE_MGMT: {
        timeline: {
            title: 'Visual Representation',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.PRO_TIP,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'See all your cycles arranged in a timeline view. Use the handy quick access shortcuts to copy, export and see previews.',
                    },
                },
            ],
        },
        archive: {
            title: 'Keep It Clean',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.PRO_TIP,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'You can archive completed cycles to get them out of sight. We suggest to only keep the ones visible that you are intending to use as templates for future cycles.',
                    },
                },
            ],
        },
    },
    CYCLE_MGMT_OBJECTIVE: {
        intro: {
            title: 'Objectives & Goals',
            autorun: true,
            visibility: SparkVisibility.INTRODUCTION,
            componentType: ComponentType.MULTI_MODAL,
            body: {
                backgroundImage: '/images/360-degree-feedback/objective_spark_bg.png',
                steps: [
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>Introducing Objective Cycles!</h1> The Objectives you and your staff create are grouped into Objective Cycles which cover a specific time period that you can define. Cycles can run in parallel, overlap or simply follow the previous period. When you start them, how long they run for, and how often, is up to you! ',
                            },
                        },
                    ],
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: "<h1>Setting up a Cycle</h1> When creating a new Objective Cycle, you can communicate the company's overarching goals for the period and guide staff to set Objectives aligned with these goals. You can also include Key Results per objective, allow staff to indicate their Completion Status and even categorise objectives!",
                            },
                        },
                    ],
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>Cycle Overviews</h1> You can get an overview of all Objective Cycles from the individual \'Cycle Management\' page and check the progress of each cycle and results from the Overview screens. <br /><br />For more details check out our <a href="https://help.small-improvements.com/article/279-general-objectives-information" target="_blank" rel="noopener noreferrer">documentation</a>.',
                            },
                        },
                    ],
                ],
            },
        },
    },
    CYCLE_MGMT_REVIEW: {
        intro: {
            title: 'Performance Reviews',
            autorun: true,
            visibility: SparkVisibility.INTRODUCTION,
            componentType: ComponentType.MULTI_MODAL,
            body: {
                backgroundImage: '/images/360-degree-feedback/review_spark_bg.png',
                steps: [
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>Introducing Performance Review Cycles!</h1>Each round of Performance Reviews are grouped into a Review Cycle which covers a specific time period defined by you. Cycles can run in parallel, overlap or simply follow the previous period. When you start them, how long they run for, and how often, is up to you!',
                            },
                        },
                    ],
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>The Review Cycle process</h1> When setting up a Review Cycle, you can choose reviewees, setting up permissions and define the questions and timeline. You can always edit your cycle later on, check the progress from the overview screens and send out nudges to those running late.',
                            },
                        },
                    ],
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>Cycle Overviews</h1> You can get an overview of all Review Cycles from the individual \'Cycle Management\' page and check the progress of each cycle and results from the Overview screens. <br /><br />For more details check out our <a href="https://help.small-improvements.com/article/287-general-performance-review-information" target="_blank" rel="noopener noreferrer">documentation</a>.',
                            },
                        },
                    ],
                ],
            },
        },
    },
    CYCLE_MGMT_FEEDBACK: {
        intro: {
            title: '360° Feedback',
            autorun: true,
            visibility: SparkVisibility.INTRODUCTION,
            componentType: ComponentType.MULTI_MODAL,
            body: {
                backgroundImage: '/images/360-degree-feedback/feedback_spark_bg.png',
                steps: [
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>Introducing Feedback Cycles!</h1> A cycle defines the how and when each round of 360° Feedback occurs. Cycles can run in parallel or overlap. You can decide when to start a cycle, how long it runs for and how often. ',
                            },
                        },
                    ],
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>The 360° process</h1> You are guided through the process which includes choosing reviewees, setting up permissions as well as defining the questions and timeline. You can always edit your cycle later on, check the progress from the overview screens and send out nudges to those running late.',
                            },
                        },
                    ],
                    [
                        {
                            type: BodyType.TEXT,
                            payload: {
                                text: '<h1>Get Into It!</h1> You\'re ready to go! For more details check out our <a href="https://help.small-improvements.com/article/287-general-performance-review-information" target="_blank" rel="noopener noreferrer">documentation</a>.',
                            },
                        },
                    ],
                ],
            },
        },
    },
    FEEDBACK: {
        allowUnsolicitedFeedback: {
            title: 'Proactive Feedback',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'Give every employee a chance to provide feedback, even if they weren\'t nominated.\n<a href="http://blog.small-improvements.com/2016/12/12/360-feedback-from-anyone-introducing-proactive-feedback-beta/" target="_blank" rel="noopener noreferrer">Click to read more on our blog!</a>',
                    },
                },
            ],
        },
    },
    SAMPLE_CONTENT: {
        noOpenCycles: {
            title: 'Welcome back to Small Improvements!',
            autorun: true,
            componentType: ComponentType.MODAL,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: {
                content: [
                    {
                        type: BodyType.TEXT,
                        payload: {
                            text: 'We have noticed you currently have no open cycles. To get the best evaluation experience, you can refresh your sample content on the <a href="/vacuumSampleCompany">Sample Content and Deletion</a> screen.',
                        },
                    },
                ],
            },
        },
        oldCreationDate: {
            title: 'Welcome back to Small Improvements!',
            autorun: true,
            componentType: ComponentType.MODAL,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: {
                content: [
                    {
                        type: BodyType.TEXT,
                        payload: {
                            text: 'We noticed your sample content was generated over six months ago. To ensure you are able to evaluate the latest features, you can refresh your sample content on the <a href="/vacuumSampleCompany">Sample Content and Deletion</a> screen.',
                        },
                    },
                ],
            },
        },
    },
    FEATURE: {
        chat: {
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            autorun: true,
            body: ChatPromotion,
        },
    },
    AUTOMATED_BILLING: {
        promptToCheckout: {
            containerless: true,
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            autorun: true,
            persistent: true,
            body: PromptToCheckout,
        },
    },
    OBJECTIVECYCLE: {
        timeline: {
            title: 'Update objectives whenever you like!',
            componentType: ComponentType.POPOVER,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: [
                {
                    type: BodyType.TEXT,
                    payload: {
                        text: 'This option will allow employees to update key results, completion status, percentage, and' +
                            ' rating of their objectives (if these features are enabled) even after the cycle is closed.' +
                            ' You can see when an update happened in the objective’s activity log.' +
                            ' Disable this option if you would like to lock down updates after a specific date.',
                    },
                },
            ],
        },
    },
    RESTRICTED_CYCLE: {
        inform: {
            title: 'Select cycle participants',
            autorun: false,
            componentType: ComponentType.MODAL,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: {
                content: [
                    {
                        type: BodyType.TEXT,
                        payload: {
                            text: 'You can now limit the cycle to certain participants or open it to all participants through the Admin menu on this screen.',
                        },
                    },
                ],
            },
        },
    },
    RESTRICTED_CYCLE_ABOVE_CYCLE_LIST: {
        inform: {
            title: 'Limited Cycles',
            autorun: false,
            componentType: ComponentType.MODAL,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: {
                content: [
                    {
                        type: BodyType.TEXT,
                        payload: {
                            text: 'To select which employees you want to participate in the cycle, visit the cycle overview and select Add Participants. <a href="https://help.small-improvements.com/article/284-managing-restrictions-in-limited-objective-cycles">Learn more about how this works</a>.',
                        },
                    },
                ],
            },
        },
    },
    MEETINGS: {
        calendarSettings: {
            autorun: true,
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: CalendarSettingsAlert,
        },
        actionItems: {
            autorun: true,
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: MeetingActionSpark,
        },
    },
    AI_ASSISTANT: {
        toolbarButton: {
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: WritingAssistantSparkContent,
        },
    },
    UPGRADE_PLAN: {
        inform: {
            autorun: true,
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: UpgradePlanPanel,
        },
        test: {
            autorun: true,
            componentType: ComponentType.INLINE,
            visibility: SparkVisibility.ANNOUNCEMENT,
            body: UpgradePlanPanel,
        },
    },
};
export default SPARK_CONTENT;
/* eslint-enable max-len */
