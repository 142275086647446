import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { isEmpty, map, noop } from 'lodash/fp';
import Quill from 'quill';
import { Component, createElement } from 'react';
import ReactDOM from 'react-dom';
import { getNext, getPrevious } from '../../../../services/List';
import { parseContent } from './parser';
import Button from '../../../../components/Button';
import styles from './style.m.less';
const Parchment = Quill.import('parchment');
const asPx = str => `${str}px`;
export function ResultsContainer({ children, style, className }) {
    return (_jsx("div", { className: classNames(styles.container, className), style: style, children: children }));
}
export function ResultsItem({ onClick, onHighlight, isHighlighted, children, className, style }) {
    const cN = classNames(styles.item, { [styles.highlighted]: isHighlighted });
    return (_jsx(Button, { kind: "PLAIN", onClick: onClick, onMouseOver: onHighlight, className: classNames(cN, className), style: style, children: children }));
}
export function ResultsMoreItems({ count, children }) {
    const body = children || `and ${count} more`;
    return _jsx("div", { className: styles.moreItems, children: body });
}
function canShowMoreItems(moreItemsComponent, items, maxItemsShown) {
    return moreItemsComponent && maxItemsShown && items.length > maxItemsShown;
}
class AutocompleteWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShown: false,
            highlightedItem: null,
            items: null,
        };
    }
    onKeydown(event) {
        const actions = {
            Escape: () => this.hide(),
            Enter: () => this.selectHighlighted(),
            ArrowUp: () => this.highlightPrevious(this.getDisplayedItems()),
            ArrowDown: () => this.highlightNext(this.getDisplayedItems()),
        };
        const action = actions[event.key];
        if (action && this.isCompletionMenuOpen()) {
            action();
            event.stopPropagation();
            event.preventDefault();
        }
    }
    show() {
        this.setState({ isShown: true });
    }
    hide() {
        this.setState({ isShown: false });
    }
    isShown() {
        return this.state.isShown;
    }
    search(query) {
        this.setState({ query });
        this.props.onSearch(query).then(items => {
            this.setState({ items, highlightedItem: items[0] });
        });
    }
    openWithItems(query, items) {
        this.setState({ query, items, isShown: true, highlightedItem: items[0] });
    }
    onHighlight(item) {
        this.setState({ highlightedItem: item });
    }
    highlightNext(items) {
        const { highlightedItem } = this.state;
        this.onHighlight(getNext(items, highlightedItem));
    }
    highlightPrevious(items) {
        const { highlightedItem } = this.state;
        this.onHighlight(getPrevious(items, highlightedItem));
    }
    getDisplayedItems() {
        const { items } = this.state;
        const { maxItemsShown } = this.props;
        return (items || []).slice(0, maxItemsShown || Infinity);
    }
    selectHighlighted() {
        const { highlightedItem } = this.state;
        if (highlightedItem) {
            this.selectItem(highlightedItem);
        }
    }
    selectItem(item) {
        const { query } = this.state;
        const { onSelect } = this.props;
        onSelect(item, query);
    }
    isCompletionMenuOpen() {
        const { isShown, items } = this.state;
        return isShown && !isEmpty(items);
    }
    render() {
        const { items, highlightedItem } = this.state;
        const { wrapperComponent, itemComponent, moreItemsComponent, maxItemsShown, itemToKey = item => item.id, } = this.props;
        if (!this.isCompletionMenuOpen()) {
            return null;
        }
        const onClick = item => this.selectItem(item);
        const itemsToDisplay = this.getDisplayedItems();
        const renderMoreItems = canShowMoreItems(moreItemsComponent, items, maxItemsShown)
            ? count => createElement(moreItemsComponent, { count })
            : () => null;
        const renderItems = map(item => {
            const props = {
                key: itemToKey(item),
                item,
                isHighlighted: item === highlightedItem,
                onClick: () => onClick(item),
                onHighlight: () => this.onHighlight(item),
            };
            return createElement(itemComponent, props);
        });
        const renderBody = () => (_jsxs("div", { children: [renderItems(itemsToDisplay), renderMoreItems(items.length - maxItemsShown)] }));
        return createElement(wrapperComponent, {}, renderBody());
    }
}
const checkForTriggers = (quill, instance, selection, triggers) => {
    if (!selection) {
        return instance.hide();
    }
    const { length, index } = selection;
    if (length) {
        return instance.hide();
    }
    const [leaf, offset] = quill.getLeaf(index);
    if (!(leaf instanceof Parchment.TextBlot)) {
        return;
    }
    const leafResult = parseContent(leaf.value(), offset, triggers);
    if (!leafResult) {
        return instance.hide();
    }
    // As we searched for our trigger only within the space of our current Leaf blot,
    // we need to calibrate the real start and end positions of our trigger in respect
    // to our current index.
    // Check how much the cursor moved forward inside the leaf and subtract this value from the index
    const start = index - (offset - leafResult.start);
    // Move the end by the same amount as the start moved
    const end = leafResult.end + start - leafResult.start;
    const result = Object.assign(Object.assign({}, leafResult), { start,
        end, index: start, length: end - start });
    if (!instance.isShown()) {
        instance.show(quill.getBounds(result.start));
    }
    instance.search(result);
};
const listen = (quill, instance, triggers) => {
    quill.on('text-change', () => checkForTriggers(quill, instance, quill.getSelection(), triggers));
    quill.on('selection-change', sel => {
        // here we check if the user just moved away from
        // the completion menu!
        if (instance.isShown() && sel) {
            checkForTriggers(quill, instance, sel, triggers);
        }
        // onBlur
        if (!sel) {
            // this blur might be triggered from within a completion element we're currently
            // using. Give its handler a chance to perform an action, and only blur if
            // it didn't reestablish focus
            setTimeout(() => {
                if (!quill.getSelection()) {
                    instance.hide();
                }
            });
        }
    });
};
export const replaceQueryWithEmbed = (quill, query, embedType, embedValue) => {
    const { index, length } = query;
    quill.deleteText(index, length);
    quill.insertEmbed(index, embedType, embedValue);
    setTimeout(() => {
        quill.insertText(index + 1, ' ');
        quill.setSelection(index + 2);
    });
};
export const replaceQueryWithText = (quill, query, text) => {
    const { index, length } = query;
    quill.deleteText(index, length);
    quill.insertText(index, text);
    setTimeout(() => {
        quill.insertText(index + text.length, ' ');
        quill.setSelection(index + text.length + 1);
    });
};
export const replaceQueryWithEmoji = (quill, query, emojiText) => {
    const { index, length } = query;
    quill.deleteText(index, length);
    quill.insertText(index, emojiText);
    setTimeout(() => {
        quill.setSelection(index + emojiText.length + 1);
    });
};
export const createInstance = ({ quill, container, wrapperComponent, itemComponent, moreItemsComponent, maxItemsShown, itemToKey, onSearch, onSelect, }) => {
    const dummy = {
        isShown: () => false,
        show: noop,
        hide: noop,
        openWithItems: noop,
    };
    let component = dummy;
    ReactDOM.render(createElement(AutocompleteWrapper, {
        wrapperComponent,
        itemComponent,
        moreItemsComponent,
        maxItemsShown,
        itemToKey,
        onSearch,
        onSelect,
        ref: c => (component = c ? c : dummy),
    }), container);
    const onKeydown = e => {
        if (typeof component.onKeydown === 'function') {
            component.onKeydown(e);
        }
    };
    const show = position => {
        container.style.display = 'block';
        container.style.position = 'absolute';
        container.style.zIndex = 100;
        container.style.top = asPx(position.top + position.height);
        const quillPosition = container.parentNode.getBoundingClientRect();
        const autocompleteWidth = 220; /* min-width of autocomplete */
        if (position.left + autocompleteWidth > quillPosition.width) {
            container.style.left = asPx(quillPosition.width - autocompleteWidth);
        }
        else {
            container.style.left = asPx(position.left);
        }
        component.show();
        quill.container.addEventListener('keydown', onKeydown, true);
    };
    const hide = () => {
        container.style.display = 'none';
        component.hide();
        quill.container.removeEventListener('keydown', onKeydown, true);
    };
    const search = val => component.search(val);
    const isShown = () => component.isShown();
    const instance = {
        show,
        hide,
        search,
        isShown,
    };
    const check = (selection, triggers) => checkForTriggers(quill, instance, selection, triggers);
    const checkDeferred = (selection, triggers) => setTimeout(() => check(selection, triggers), 10);
    const openItemsAtIndex = (items, index) => {
        show(quill.getBounds(index));
        component.openWithItems({
            index,
            start: index,
            end: index,
            length: 0,
            string: '',
        }, items);
    };
    return Object.assign(Object.assign({}, instance), { listen: triggers => listen(quill, instance, triggers), check,
        checkDeferred,
        openItemsAtIndex });
};
