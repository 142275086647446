// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SlideShow-container-1GsV6 {\n  position: relative;\n  overflow: hidden;\n  margin: 0 -20px;\n}\n.SlideShow-slider-3FjDX {\n  white-space: nowrap;\n  max-width: 100vw;\n  transition: transform 0.2s;\n}\n.SlideShow-item-3aRiO {\n  width: 100%;\n  display: inline-block;\n  white-space: normal;\n  vertical-align: top;\n  padding: 0 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "SlideShow-container-1GsV6",
	"slider": "SlideShow-slider-3FjDX",
	"item": "SlideShow-item-3aRiO"
};
module.exports = exports;
