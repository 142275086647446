import { includes } from 'lodash';
export function matchOn(field, user, query) {
    return includes(user.data[field].toLowerCase(), query.toLowerCase());
}
export function wordMatches(field, user, query) {
    return includes(user.data[field].toLowerCase().split(' '), query.toLowerCase());
}
export function highlight(text, query) {
    if (text && query) {
        const start = text.toLowerCase().indexOf(query.toLowerCase());
        const end = start + query.length;
        if (start === -1) {
            return text;
        }
        else {
            const init = text.substring(0, start);
            const word = text.substring(start, end);
            const tail = text.substring(end);
            return init + '<strong>' + word + '</strong>' + tail;
        }
    }
    else {
        return text;
    }
}
