import { jsx as _jsx } from "react/jsx-runtime";
import * as autocompletion from '../../helpers/autocompletion';
import Replacement from './format';
import { ALL_CHOICES, TRIGGERS } from './replacements';
const NAME = 'replacements';
const STYLE = { minWidth: '220px' };
function Choice(props) {
    return (_jsx(autocompletion.ResultsItem, Object.assign({}, props, { style: STYLE, children: props.item.label })));
}
class Replacements {
    constructor(quill) {
        this.openItemsAtIndex = index => this.instance.openItemsAtIndex(ALL_CHOICES, index);
        const container = quill.addContainer('ql-replacement');
        this.instance = autocompletion.createInstance({
            quill,
            container,
            wrapperComponent: autocompletion.ResultsContainer,
            itemComponent: Choice,
            moreItemsComponent: autocompletion.ResultsMoreItems,
            maxItemsShown: Infinity,
            itemToKey: item => item.value,
            onSearch: query => Promise.resolve(query.trigger.choices),
            onSelect: (item, query) => {
                this.instance.hide();
                autocompletion.replaceQueryWithEmbed(quill, query, Replacement.blotName, {
                    value: item.value,
                    serialization: item.replacement,
                });
            },
        });
        this.instance.listen(TRIGGERS);
    }
}
export const name = NAME;
export const handler = Replacements;
export const formats = [Replacement];
export const toolbarElements = [
    {
        key: 'replacements',
        type: 'insertDropdown',
        onClick: ({ quill, getLastSelection }) => {
            const lastSelection = getLastSelection();
            quill.setSelection(lastSelection);
            quill.getModule(NAME).openItemsAtIndex(lastSelection.index);
        },
        label: _jsx("span", { children: "Replacements" }),
    },
];
