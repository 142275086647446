// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmModal-container-3gkxh {\n  display: flex;\n  flex-direction: column;\n}\n.ConfirmModal-body-3WOPF {\n  flex: 1 1 auto;\n  overflow: auto;\n}\n.ConfirmModal-buttonContainer-2ex1j {\n  flex: 0 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ConfirmModal-container-3gkxh",
	"body": "ConfirmModal-body-3WOPF",
	"buttonContainer": "ConfirmModal-buttonContainer-2ex1j"
};
module.exports = exports;
