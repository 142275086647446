import { findIndex, isEqual } from 'lodash/fp';
export const getNext = (items, item, { wrap = true } = {}) => {
    const first = items[0];
    if (item === undefined) {
        return first;
    }
    const currentIndex = findIndex(isEqual(item), items);
    if (currentIndex === -1) {
        return null;
    }
    const nextItem = items[currentIndex + 1];
    if (nextItem === undefined) {
        return wrap ? first : items[currentIndex];
    }
    return nextItem;
};
export const getPrevious = (items, item, { wrap = true } = {}) => {
    const last = items[items.length - 1];
    if (item === undefined) {
        return last;
    }
    const currentIndex = findIndex(isEqual(item), items);
    if (currentIndex === -1) {
        return null;
    }
    if (currentIndex === 0) {
        return wrap ? last : items[0];
    }
    return items[currentIndex - 1];
};
/**
 * Stolen and adapted from https://stackoverflow.com/a/5306832/4064285
 */
export const moveItem = (items, currentIndex, newIndex) => {
    if (newIndex >= items.length) {
        newIndex = items.length - 1;
    }
    const newItems = [...items];
    newItems.splice(newIndex, 0, newItems.splice(currentIndex, 1)[0]);
    return newItems;
};
