export const PLANS = [
    {
        id: 8,
        title: 'Launch',
        price: '3',
        description: 'Start your journey with essential HR tools designed to streamline your operations and drive performance.',
        features: ['Performance Reviews', 'Praise', 'Company Directory', 'HRIS Integrations', 'Google Sign-In'],
    },
    {
        id: 9,
        title: 'Grow',
        price: '6',
        description: 'Take your team to the next level with advanced feedback and engagement tools for a thriving workplace.',
        featureTitle: 'Everything in Launch',
        features: ['360 & Anytime Feedback', 'Pulse Surveys', '1:1 Meetings', 'Slack Integration'],
    },
    {
        id: 10,
        title: 'Elevate',
        price: '9',
        description: 'Unlock the full potential of your organization with comprehensive analytics and tailored customisation for ultimate efficiency.',
        featureTitle: 'Everything in Grow',
        features: [
            'Objectives',
            'Unlimited Surveys',
            'Insights Analytics',
            'All Integrations',
            'All Customizations',
            'SAML',
        ],
    },
];
