// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Blocks-container-2rjAL {\n  overflow: hidden;\n  display: flex;\n  border: 5px solid white;\n}\n.Blocks-container-2rjAL:before,\n.Blocks-container-2rjAL:after {\n  background-color: white;\n  flex: 1;\n  min-width: 0;\n}\n.Blocks-container-2rjAL:after {\n  content: '';\n}\n.Blocks-containerCentered-15b2a {\n}\n.Blocks-containerCentered-15b2a:before {\n  content: '';\n}\n.Blocks-common-Px3f0 {\n  box-sizing: content-box;\n  border-style: solid;\n  border-color: white;\n  width: 100%;\n}\n.Blocks-blockBorder-jcxWt {\n  border-radius: 6px;\n  border-width: 3px;\n  margin: -3px;\n}\n.Blocks-avatarLarge-3wpII {\n  width: 100px;\n  height: 100px;\n  border-radius: 200px;\n  border-width: 100px;\n  margin: -100px;\n}\n.Blocks-headingLine-2oIfN {\n  height: 20px;\n}\n.Blocks-subHeadingLine-3hiTx {\n  height: 18px;\n}\n.Blocks-line-agTJu {\n  height: 10px;\n}\n.Blocks-space-3bKAC {\n  height: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Blocks-container-2rjAL",
	"containerCentered": "Blocks-containerCentered-15b2a Blocks-container-2rjAL",
	"common": "Blocks-common-Px3f0",
	"blockBorder": "Blocks-blockBorder-jcxWt",
	"avatarLarge": "Blocks-avatarLarge-3wpII Blocks-common-Px3f0",
	"headingLine": "Blocks-headingLine-2oIfN Blocks-common-Px3f0 Blocks-blockBorder-jcxWt",
	"subHeadingLine": "Blocks-subHeadingLine-3hiTx Blocks-common-Px3f0 Blocks-blockBorder-jcxWt",
	"line": "Blocks-line-agTJu Blocks-common-Px3f0 Blocks-blockBorder-jcxWt",
	"space": "Blocks-space-3bKAC"
};
module.exports = exports;
