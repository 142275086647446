// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Input-inputWrapper-2KlIK {\n  position: relative;\n}\n.Input-inputWrapperWithError-StOoX input,\n.Input-inputWrapperWithError-StOoX .text-input,\n.Input-inputWrapperWithError-StOoX input:hover {\n  border-color: #f23037;\n  background-color: #fff3f3;\n}\n.Input-inputWrapperWithError-StOoX input:active,\n.Input-inputWrapperWithError-StOoX input:focus {\n  border-color: #f23037;\n  background-color: #fff3f3;\n  box-shadow: 0 0 0 1px #f23037;\n}\n", ""]);
// Exports
exports.locals = {
	"inputWrapper": "Input-inputWrapper-2KlIK",
	"inputWrapperWithError": "Input-inputWrapperWithError-StOoX"
};
module.exports = exports;
