import Fuse from 'fuse.js';
const EMOJI_SUGGESTIONS = [
    { char: '👍', name: 'Nice work!' },
    { char: '🙌', name: 'Hurray!' },
    { char: '🙏', name: 'Thanks!' },
    { char: '✔️', name: 'Done!' },
    { char: '🎉', name: 'Congrats!' },
];
const SEARCHABLE_FIELDS = ['name'];
class Searcher {
    constructor() {
        this.fuse = null;
    }
    withFuse() {
        if (this.fuse) {
            return Promise.resolve(this.fuse);
        }
        return import(/* webpackChunkName: 'Emojis' */ '!val-loader!./emojis').then(({ default: emojis }) => {
            const allEmojis = [...EMOJI_SUGGESTIONS, ...emojis];
            this.fuse = new Fuse(allEmojis, { keys: SEARCHABLE_FIELDS, threshold: 0.3 });
            return this.fuse;
        });
    }
    search(t) {
        if (!t) {
            return Promise.resolve(EMOJI_SUGGESTIONS);
        }
        return this.withFuse().then(fuse => fuse.search(t));
    }
}
export default new Searcher();
