import { curry, values } from 'lodash/fp';
import Quill from 'quill';
import { sanitize } from '../../../../../../common/services/sanitizer/service';
import './style.m.less';
const Embed = Quill.import('blots/embed');
const ATTRS = {
    SERIALIZATION: 'data-serialization',
    VALUE: 'data-value',
};
class AutocompleteBlot extends Embed {
    static create(value) {
        const node = super.create(value);
        node.setAttribute(ATTRS.SERIALIZATION, value.serialization);
        node.setAttribute(ATTRS.VALUE, sanitize(value.value));
        node.textContent = value.value;
        return node;
    }
    static formats(node) {
        return values(ATTRS).reduce((mem, attr) => {
            if (node.hasAttribute(attr)) {
                mem[attr] = node.getAttribute(attr);
            }
            return mem;
        }, {});
    }
    static value(node) {
        return {
            value: node.getAttribute(ATTRS.VALUE),
            serialization: node.getAttribute(ATTRS.SERIALIZATION),
        };
    }
}
AutocompleteBlot.blotName = 'autocomplete';
AutocompleteBlot.tagName = 'autocomplete-blot';
AutocompleteBlot.className = 'rte-autocomplete';
export default AutocompleteBlot;
// Create a serialize compatible with AutocompleteBlot (see AutocompleteBlot.create)
export const mkSerialize = curry((onSerialization, input) => {
    const { tagName } = AutocompleteBlot;
    const regexp = new RegExp(`<${tagName} (.+?)>.*?</${tagName}>`, 'gi');
    return input.replace(regexp, (m, data) => {
        const serializationMatch = data.match('data-serialization="(.+?)"');
        const valueMatch = data.match('data-value="(.+?)"');
        if (serializationMatch && valueMatch) {
            const serialization = serializationMatch[1];
            const value = valueMatch[1];
            onSerialization({ value, serialization });
            return serialization;
        }
        return m;
    });
});
