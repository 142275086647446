// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InputPrefix-inputPrefix-3Z-oR {\n  position: absolute;\n  top: 1px;\n  bottom: 1px;\n  left: 1px;\n  color: #7a7a79;\n  background-color: #f5f4f3;\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"inputPrefix": "InputPrefix-inputPrefix-3Z-oR"
};
module.exports = exports;
