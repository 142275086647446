import { jsx as _jsx } from "react/jsx-runtime";
import { PropTypes } from 'prop-types';
import styles from './style.m.less';
export default function InputPrefix({ prefix, prefixSize }) {
    const style = {
        width: `${prefixSize}px`,
    };
    return prefix ? (_jsx("div", { className: styles.inputPrefix, style: style, children: prefix })) : null;
}
InputPrefix.propTypes = {
    prefix: PropTypes.string,
    prefixSize: PropTypes.number,
};
