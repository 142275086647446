// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TodoItems-userSelect-2q0Ue {\n  user-select: none;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n}\n.TodoItems-todo-items-group-3J3L8 {\n  margin-bottom: 20px;\n}\nh3.TodoItems-todo-items-group-days-until-title-2yHaS {\n  color: #a3a2a1;\n  font-family: 'TTNormsPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  font-variant: tabular-nums;\n  margin-bottom: 5px;\n}\n.TodoItems-todo-items-group-3J3L8:first-child .TodoItems-todo-items-group-days-until-title-2yHaS {\n  margin-top: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"userSelect": "TodoItems-userSelect-2q0Ue",
	"todo-items-group": "TodoItems-todo-items-group-3J3L8",
	"todo-items-group-days-until-title": "TodoItems-todo-items-group-days-until-title-2yHaS"
};
module.exports = exports;
