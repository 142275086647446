import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get, getOr, noop } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Button from '../../components/Button';
import DeprecatedButtonContainer from '../../components/ButtonContainer';
import Modal from '../../components/Modal';
import { withDisabledCheck as withDisabledCheckService } from '../../services/withDisabledCheck/service';
const propTypes = {
    disabledCheck: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};
const defaultProps = {
    onClose: noop,
};
export default function withDisabledCheck(WrappedComponent, handlerName = 'onClick') {
    class ComponentWithModal extends Component {
        constructor(props) {
            super(props);
            this.state = { modalOpen: false };
            this.onClose = this.onClose.bind(this);
            this.onOpen = this.onOpen.bind(this);
            this.originalHandler = get(handlerName)(props) || noop;
            this.onClick = withDisabledCheckService(this.originalHandler, props.disabledCheck, this.onOpen);
        }
        onClose() {
            this.setState({ modalOpen: false });
            this.props.onClose();
        }
        onOpen(customModalProps) {
            this.setState({ modalOpen: true, customModalProps });
        }
        render() {
            const modalProps = {
                noCloseIcon: true,
                title: `Sorry, you're not allowed to confirm that operation.`,
                closeLabel: getOr('Close', 'closeLabel')(this.state.customModalProps),
                size: 'MD_WIDTH',
                isOpen: this.state.modalOpen,
                onClose: this.onClose,
                closeClass: get('closeClass')(this.state.customModalProps),
            };
            return (_jsxs(WrappedComponent, { onClick: this.onClick, children: [this.props.children, _jsxs(Modal, Object.assign({}, modalProps, this.state.customModalProps, { children: [get('body')(this.state.customModalProps), _jsx(DeprecatedButtonContainer, { children: _jsx(Button, { onClick: this.onClose, className: modalProps.closeClass, children: modalProps.closeLabel }) })] }))] }));
        }
    }
    ComponentWithModal.propTypes = propTypes;
    ComponentWithModal.defaultProps = defaultProps;
    return ComponentWithModal;
}
