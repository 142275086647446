import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Card } from '../../Cards';
import { AvatarLarge, HeadingLine, Line, Space, SubHeadingLine } from '../Blocks';
import { Gradient, GradientContainer, Mask } from '../Layers/index';
import styles from '../Layers/style.m.less';
function DefaultStyle() {
    return (_jsxs("div", { className: styles.container, children: [_jsxs(Gradient, { className: styles.sentence, children: [_jsx(Mask, { className: styles.contentImageGap }), _jsx(Mask, { className: styles.contentSentenceGap })] }), _jsx("div", { className: "card", children: _jsxs(Gradient, { children: [_jsx(Mask, { className: styles.headerL }), _jsx(Mask, { className: styles.headerR }), _jsx(Mask, { className: styles.headerGap }), _jsx(Mask, { className: styles.subHeaderL }), _jsx(Mask, { className: styles.subHeaderR }), _jsx(Mask, { className: styles.contentTop }), _jsx(Mask, { className: styles.contentFirstEnd }), _jsx(Mask, { className: styles.contentSecondLine }), _jsx(Mask, { className: styles.contentSecondEnd })] }) })] }));
}
function TodoStyle() {
    return (_jsxs("div", { className: classNames(styles.container, styles.todo), children: [_jsx(Gradient, { className: styles.sentence, children: _jsx(Mask, { className: styles.contentSentenceGap }) }), _jsx("div", { className: "card", children: _jsxs(Gradient, { className: styles.cardContent, children: [_jsx(Mask, { className: styles.headerL }), _jsx(Mask, { className: styles.headerR }), _jsx(Mask, { className: styles.headerGap }), _jsx(Mask, { className: styles.subHeaderL }), _jsx(Mask, { className: styles.subHeaderR }), _jsx(Mask, { className: styles.contentSecondEnd })] }) })] }));
}
function PlainCardStyle() {
    return (_jsx("div", { className: classNames(styles.container, styles.plainCard), children: _jsx("div", { className: "card", children: _jsxs(Gradient, { className: styles.cardContent, children: [_jsx(Mask, { className: styles.headerGap }), _jsx(Mask, { className: styles.subHeaderR }), _jsx(Mask, { className: styles.contentTop }), _jsx(Mask, { className: styles.contentFirstEnd }), _jsx(Mask, { className: styles.contentSecondLine }), _jsx(Mask, { className: styles.contentSecondEnd }), _jsx(Mask, { className: styles.contentThirdLine }), _jsx(Mask, { className: styles.contentThirdEnd })] }) }) }));
}
function PraiseStyle() {
    return (_jsx(Card, { children: _jsxs(GradientContainer, { children: [_jsx(Space, {}), _jsx(AvatarLarge, { centered: true }), _jsx(Space, {}), _jsx(HeadingLine, { centered: true, width: 60 }), _jsx(Space, {}), _jsx(Space, {}), _jsx(SubHeadingLine, {}), _jsx(SubHeadingLine, { width: 70 }), _jsx(Space, {}), _jsx(Line, {}), _jsx(Line, {}), _jsx(Line, {})] }) }));
}
export { DefaultStyle, PlainCardStyle, PraiseStyle, TodoStyle };
