import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../../components/Button';
import * as PromoBox from '../../components/PromoBox';
import ChatPromotionModal from './Modal';
import styles from './style.m.less';
export default function ChatPromotion({ onDismiss }) {
    const [showModal, setShowModal] = useState(false);
    return (_jsxs(PromoBox.Container, { children: [_jsxs(PromoBox.Header, { children: [_jsxs(PromoBox.Heading, { children: ["Using ", _jsx("img", { className: styles.icon, src: require('./slack-white.svg'), alt: "" }), "Slack?"] }), _jsx(PromoBox.CloseButton, { onClick: onDismiss })] }), _jsx("img", { className: styles.illustration, src: require('./illustration.svg'), alt: "" }), _jsx(PromoBox.Paragraph, { children: "Bring employee recognition and objectives to your company's favorite messaging tool - right where the conversation is already happening." }), _jsx(PromoBox.CtaContainer, { children: _jsx(Button, { className: "small outline", onClick: () => setShowModal(true), children: "Learn more" }) }), _jsx(ChatPromotionModal, { isOpen: showModal, onClose: () => setShowModal(false) })] }));
}
