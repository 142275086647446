import { set } from 'lodash'; // need a mutating version here
import { forEach, keyBy, keys, reduce } from 'lodash/fp';
import Quill from 'quill';
import { getIconByName } from '../../../legacy/modules/icons';
import * as autoConversions from './modules/autoConversions';
import * as autoLinks from './modules/autoLinks';
import * as customReplacements from './modules/customReplacements';
import * as emojis from './modules/emojis';
import * as madlibs from './modules/madlibs';
import * as mentions from './modules/mentions';
import * as replacements from './modules/replacements';
import * as submitThroughKeyboardHint from './modules/submitThroughKeyboardHint';
import * as writingAssistant from './modules/writingAssistant';
import './overrides/link';
export const isMac = () => typeof window.navigator !== 'undefined' && /Mac/i.test(window.navigator.platform);
export const SHORTKEY = isMac() ? 'metaKey' : 'ctrlKey';
export const isSubmission = event => event.key === 'Enter' && event[SHORTKEY];
export const SERIALIZERS = {};
export const DESERIALIZERS = {};
export const MODULES_WITH_TOOLBAR_ELEMENTS = {};
const ALL_MODULES = [
    autoConversions,
    autoLinks,
    mentions,
    replacements,
    customReplacements,
    emojis,
    madlibs,
    submitThroughKeyboardHint,
    writingAssistant,
];
export const MODULES = keyBy('name', ALL_MODULES);
const registerModule = mod => Quill.register(`modules/${mod.name}`, mod.handler);
const registerFormat = f => {
    Quill.register(f);
};
const registerSerializer = format => {
    if (format.siSerialize) {
        SERIALIZERS[format.blotName] = format.siSerialize;
    }
};
const registerDeserializer = format => {
    if (format.siDeserialize) {
        DESERIALIZERS[format.blotName] = format.siDeserialize;
    }
};
const registerToolbarElements = mod => {
    if (mod.toolbarElements && mod.toolbarElements.length) {
        MODULES_WITH_TOOLBAR_ELEMENTS[mod.name] = mod.toolbarElements;
    }
};
const getFormats = reduce((mem, mod) => [...mem, ...(mod.formats || [])], []);
const allFormats = getFormats(ALL_MODULES);
const ICONS = {
    bold: 'bold',
    italic: 'italic',
    underline: 'underline',
    strike: 'strikethrough',
    'code-block': 'code',
    link: 'link',
    'list.bullet': 'list',
    'list.ordered': 'list-numbered',
    'indent.-1': 'indent-less',
    'indent.+1': 'indent',
};
const setupIcons = () => {
    const icons = Quill.import('ui/icons');
    forEach(k => {
        getIconByName(ICONS[k]).then(icon => set(icons, k, icon));
    }, keys(ICONS));
};
forEach(registerModule, ALL_MODULES);
forEach(registerToolbarElements, ALL_MODULES);
forEach(registerFormat, allFormats);
forEach(registerSerializer, allFormats);
forEach(registerDeserializer, allFormats);
setupIcons();
