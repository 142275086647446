import { useEffect, useState } from 'react';
import { getCustomer$ } from './services/request';
/**
 * Null means there is no customer
 * undefined means "don't know"/request in progress
 */
export default function useCustomer() {
    const [customer, setCustomer] = useState();
    useEffect(() => {
        const subscription = getCustomer$().subscribe(data => setCustomer(data), () => setCustomer(null));
        return subscription.unsubscribe;
    }, []);
    return customer;
}
