// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormItem-hr-1FB8o {\n  margin: 0 0 30px 0;\n  border-top: 1px solid #e0dfde;\n}\n.grid + .FormItem-hr-1FB8o {\n  margin-top: 20px;\n}\n.FormItem-header-3nUY_ {\n  color: #4d4d4c;\n  font-size: 15px;\n  font-weight: 400;\n  margin-top: 0;\n  margin-bottom: 20px;\n  --fi-size: 18px;\n  --fi-stroke: var(--fi-stroke-s);\n}\n", ""]);
// Exports
exports.locals = {
	"hr": "FormItem-hr-1FB8o",
	"header": "FormItem-header-3nUY_"
};
module.exports = exports;
