import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as autocompletion from '../../helpers/autocompletion';
import ToolbarButton from '../../Toolbar/button';
import searcher from './searcher';
import { IconEmojiSmile } from 'featherico';
import styles from './style.m.less';
const { ResultsContainer, ResultsItem, ResultsMoreItems } = autocompletion;
const NAME = 'emojis';
const TRIGGER = ':';
const TRIGGERS = [{ type: 'string', value: TRIGGER }];
function Choice(props) {
    const { item } = props;
    return (_jsxs(ResultsItem, Object.assign({}, props, { children: [_jsx("span", { className: styles.char, children: item.char }), _jsx("span", { className: styles.name, children: item.name })] })));
}
class Emojis {
    constructor(quill) {
        this.check = index => this.instance.checkDeferred({ index, length: 0 }, TRIGGERS);
        const container = quill.addContainer('ql-emojis');
        const search = t => searcher.search(t);
        this.instance = autocompletion.createInstance({
            quill,
            container,
            wrapperComponent: ResultsContainer,
            itemComponent: Choice,
            moreItemsComponent: () => _jsx(ResultsMoreItems, { children: "Type for more..." }),
            maxItemsShown: 5,
            itemToKey: item => item.char,
            onSearch: query => search(query.string.slice(1)),
            onSelect: (item, query) => {
                this.instance.hide();
                autocompletion.replaceQueryWithEmoji(quill, query, item.char);
            },
        });
        this.instance.listen(TRIGGERS);
    }
}
export const name = NAME;
export const handler = Emojis;
export const toolbarElements = [
    {
        key: 'emojis',
        type: 'button',
        component: ({ quill, getLastSelection }) => {
            const onClick = () => {
                const { index } = getLastSelection();
                let additionalOffset = 0;
                let hasSpaceAfterSelection = quill.getText(index, 1) !== ' ' && quill.getText(index, 1) !== '\n';
                if (hasSpaceAfterSelection) {
                    quill.insertText(index, ' ', 'user');
                }
                let hasSpaceBeforeSelection = quill.getText(index - 1, 1) !== ' ' && quill.getText(index - 1, 1) !== '\n';
                if (hasSpaceBeforeSelection) {
                    quill.insertText(index, ' ', 'user');
                    additionalOffset++;
                }
                const nextIndex = index + additionalOffset + TRIGGER.length;
                quill.insertText(index + additionalOffset, TRIGGER, 'user');
                quill.setSelection(nextIndex);
                quill.getModule(NAME).check(nextIndex);
            };
            return (_jsx(ToolbarButton, { onClick: onClick, title: "Emoji", children: _jsx(IconEmojiSmile, {}) }));
        },
    },
];
