import { jsx as _jsx } from "react/jsx-runtime";
import { divWithClassName } from '../../../services/Component';
import styles from './style.m.less';
const blockWithClassName = className => {
    const Component = divWithClassName(className);
    return ({ centered, width }) => (_jsx("div", { className: centered ? styles.containerCentered : styles.container, children: _jsx(Component, { style: width && { width: width + '%' } }) }));
};
export const AvatarLarge = blockWithClassName(styles.avatarLarge);
export const HeadingLine = blockWithClassName(styles.headingLine);
export const SubHeadingLine = blockWithClassName(styles.subHeadingLine);
export const Line = blockWithClassName(styles.line);
export const Space = blockWithClassName(styles.space);
