// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./si-features-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".UpgradePlanPanel-panelContainer-UGqvp {\n  min-height: -webkit-fit-content;\n  min-height: -moz-fit-content;\n  min-height: fit-content;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n@media (max-width: 500px) {\n  .UpgradePlanPanel-panelContainer-UGqvp {\n    background-position-x: -80px;\n  }\n}\n.UpgradePlanPanel-list-3Cnhc {\n  padding-inline-start: 0;\n}\n.UpgradePlanPanel-listItem-3ki1x {\n  list-style: none;\n}\n.UpgradePlanPanel-panelImage-UUROG {\n  width: auto;\n  height: auto;\n  max-height: 250px;\n}\n@media (max-width: 500px) {\n  .UpgradePlanPanel-panelImage-UUROG {\n    display: none;\n  }\n}\n@media (max-width: 500px) {\n  .UpgradePlanPanel-dismissButton-3aN-8 {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"panelContainer": "UpgradePlanPanel-panelContainer-UGqvp",
	"list": "UpgradePlanPanel-list-3Cnhc",
	"listItem": "UpgradePlanPanel-listItem-3ki1x",
	"panelImage": "UpgradePlanPanel-panelImage-UUROG",
	"dismissButton": "UpgradePlanPanel-dismissButton-3aN-8"
};
module.exports = exports;
