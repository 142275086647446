import { jsx as _jsx } from "react/jsx-runtime";
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp } from 'featherico';
const DIRECTION = {
    UP: IconChevronUp,
    DOWN: IconChevronDown,
    LEFT: IconChevronLeft,
    RIGHT: IconChevronRight,
};
export default function Arrow({ className, dir = 'DOWN' }) {
    const Icon = DIRECTION[dir];
    return _jsx(Icon, { className: className });
}
