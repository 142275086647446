import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import Button from '../Button';
import DeprecatedButtonContainer from '../ButtonContainer';
import Modal from '../Modal';
const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string,
    callbacks: PropTypes.shape({
        onConfirm: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }).isRequired,
};
export default function InterceptorModal({ isOpen, message = 'Any unsaved changes will be lost. Are you sure you want to leave?', callbacks: { onCancel, onConfirm }, }) {
    return isOpen ? ( // not using the isOpen prop so the portal is always rendered last, and this stays above other modals
    _jsxs(Modal, { title: "Do you want to leave?", size: "MD_WIDTH", isOpen: true, onClose: onCancel, children: [_jsx("div", { children: _jsx("p", { children: message }) }), _jsxs(DeprecatedButtonContainer, { children: [_jsx(Button, { onClick: onCancel, children: "Stay" }), _jsx(Button, { kind: "PRIMARY", onClick: onConfirm, autoFocus: true, children: "Leave" })] })] })) : null;
}
InterceptorModal.propTypes = propTypes;
