import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { getInputProps, getValidateFunction } from '../../../services/Validation';
/**
 * Wrapper around final-form's Field, that adds support for our Validation service
 */
export const FormField = (_a) => {
    var { children, validators = [], label = '' } = _a, otherProps = __rest(_a, ["children", "validators", "label"]);
    const validate = useCallback(value => getValidateFunction(validators)(value, { label: label || undefined }), [validators, label]);
    const inputProps = useMemo(() => getInputProps(validators), [validators]);
    return (_jsx(Field, Object.assign({ validate: validate }, otherProps, { children: renderProps => children(Object.assign(Object.assign({}, renderProps), { input: Object.assign(Object.assign({}, renderProps.input), inputProps) })) })));
};
FormField.propTypes = {
    children: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    parse: PropTypes.func,
    format: PropTypes.func,
    value: PropTypes.any,
    label: PropTypes.string,
    isEqual: PropTypes.func,
    allowNull: PropTypes.bool,
    validators: PropTypes.arrayOf(PropTypes.shape({
        validate: PropTypes.func,
    })),
};
