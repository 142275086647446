import { flatten, map } from 'lodash';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createLogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { call } from 'redux-saga/effects';
import * as components from '../components';
import * as features from '../features';
import ReducerInjector from './ReducerInjector';
import SagaInjector from './SagaInjector';
import { getReducers, getSagas } from './componentSelectors';
export const reducerInjector = new ReducerInjector();
export const sagaInjector = new SagaInjector();
export const createConfiguredStore = compose(postCreate, setup);
const store = createConfiguredStore();
export default store;
const replaceReducer = () => store.replaceReducer(createReducer());
if (module.hot) {
    module.hot.accept(['../components', '../features'], replaceReducer);
}
function setup() {
    const sagaMiddleware = createSagaMiddleware();
    const reducer = createReducer();
    const middleware = createMiddleware(sagaMiddleware);
    const initialState = undefined;
    const devToolsCompose = getDevToolsCompose();
    return [createStore(reducer, initialState, devToolsCompose(applyMiddleware(...middleware))), sagaMiddleware];
}
function createReducer() {
    return combineReducers(Object.assign(Object.assign(Object.assign({}, getReducers(require('../components'))), getReducers(require('../features'))), reducerInjector.getReducers()));
}
function createMiddleware(sagaMiddleware) {
    const loggerMiddleware = getLoggerMiddleware();
    const asyncMiddleware = getAsyncMiddleware();
    return flatten([asyncMiddleware, sagaMiddleware, loggerMiddleware]);
}
function getDevToolsCompose() {
    return isDev() && window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
}
function getLoggerMiddleware() {
    return isDev() ? [createLogger({ collapsed: true, duration: true, predicate: shouldLog })] : [];
}
function getAsyncMiddleware() {
    return ( /* {dispatch, getState} */) => next => action => {
        if (typeof action === 'object' && 'payload' in action) {
            return new Promise((resolve, reject) => {
                action = Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { resolve, reject }) });
                next(action);
            });
        }
        else {
            return next(action);
        }
    };
}
function isDev() {
    return __DEV__;
}
function shouldLog() {
    return typeof window !== 'undefined' && window._DISABLE_REDUX_LOGGER !== true;
}
function postCreate([createdStore, sagaMiddleware]) {
    runSaga(sagaMiddleware);
    reducerInjector.setChangeListener(() => {
        replaceReducer();
        createdStore.dispatch({ type: '@@REDUCER_REPLACED' });
    });
    sagaInjector.setMiddleware(sagaMiddleware);
    return createdStore;
}
function runSaga(sagaMiddleware) {
    const componentSagas = getSagas(components);
    const featureSagas = getSagas(features);
    const sagas = Object.assign(Object.assign({}, componentSagas), featureSagas);
    function* mainSaga() {
        yield map(sagas, call);
    }
    sagaMiddleware.run(mainSaga);
}
