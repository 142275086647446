import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component } from 'react';
import ExportModal from './ExportModal';
const propTypes = {
    //  Pass a component that will get instantiated as initial panel in the modal.
    //  The component receives the following props:
    //    - void onExport(downloadableFile)
    //          A function that you call after you triggered the download. The
    //          modal will automatically go into progress mode and poll for the file.
    //    - void onCancel()
    //          A void function to close the modal
    bodyComponent: PropTypes.func.isRequired,
    // This is the element that will open the modal on click
    children: PropTypes.node.isRequired,
    //  An callback that is invoked after the modal gets closed.
    //  This might be used to clean up resources or to adjust the view (e.g. in an <Option />)
    onClose: PropTypes.func,
};
export default function withExportModal(WrappedComponent) {
    var _a;
    return _a = class ComponentWithModal extends Component {
            constructor() {
                super(...arguments);
                this.state = {
                    isModalOpen: false,
                };
                this.handleOpen = () => {
                    this.setState({ isModalOpen: true });
                };
                this.handleClose = () => {
                    this.setState({ isModalOpen: false });
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                };
            }
            render() {
                const _b = this.props, { onClose, bodyComponent } = _b, props = __rest(_b, ["onClose", "bodyComponent"]); // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
                return (_jsxs(_Fragment, { children: [_jsx(WrappedComponent, Object.assign({}, props, { onClick: this.handleOpen })), _jsx(ExportModal, { isOpen: this.state.isModalOpen, onClose: this.handleClose, bodyComponent: bodyComponent })] }));
            }
        },
        _a.propTypes = propTypes,
        _a;
}
