// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TooltipInfoIcon-tooltipInfoIcon-1utM0 {\n  display: inline-block;\n  color: #7a7a79;\n  cursor: pointer;\n}\n.TooltipInfoIcon-tooltipInfoIcon-1utM0:hover {\n  color: #1a1a1a;\n}\n.TooltipInfoIcon-iconMargin-YKrF5 {\n  margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"tooltipInfoIcon": "TooltipInfoIcon-tooltipInfoIcon-1utM0",
	"iconMargin": "TooltipInfoIcon-iconMargin-YKrF5"
};
module.exports = exports;
