import Fuse from 'fuse.js';
import api from '../../../../../api';
import { getTeamMembers } from '../../../../services/User';
const SEARCHABLE_FIELDS = ['name', 'loginname', 'department'];
export default class UserSearcher {
    constructor() {
        this.fuse = null;
    }
    search(val, currentUser) {
        if (!val && currentUser) {
            api.usersMedium.getAll(); // async prefetch!
            return Promise.resolve(getTeamMembers(currentUser));
        }
        if (this.fuse === null) {
            return api.usersMedium.getAll().then(users => {
                this.fuse = new Fuse(users, { keys: SEARCHABLE_FIELDS });
                return this.fuse.search(val);
            });
        }
        return Promise.resolve(this.fuse.search(val));
    }
}
