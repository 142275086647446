// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InputSubLabel-label-26KEl {\n  color: #a3a2a1;\n  font-size: 13px;\n  padding-top: 5px;\n  --fi-size: 14px;\n}\n.InputSubLabel-error-2u_Qa {\n  color: #f23037;\n}\n", ""]);
// Exports
exports.locals = {
	"label": "InputSubLabel-label-26KEl",
	"error": "InputSubLabel-error-2u_Qa InputSubLabel-label-26KEl"
};
module.exports = exports;
