// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PromoBox-box-SsG3s,\n.PromoBox-box-SsG3s a,\n.PromoBox-heading-2qM_t {\n  color: #ffffff;\n}\n.PromoBox-box-SsG3s {\n  background: #66b6ff;\n  position: relative;\n}\n.PromoBox-heading-2qM_t {\n  margin-bottom: 10px;\n  font-size: 18px;\n  font-weight: 400;\n  --fi-size: 20px;\n  --fi-stroke: var(--fi-stroke-s);\n}\n.PromoBox-header-2BajU {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n}\n.PromoBox-paragraph-2NIJp {\n  margin: 10px 0 0;\n}\n.PromoBox-paragraph-2NIJp a {\n  text-decoration: underline;\n}\n.PromoBox-buttonContainer-2pBuw {\n  justify-content: center;\n  margin-top: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"box": "PromoBox-box-SsG3s",
	"heading": "PromoBox-heading-2qM_t",
	"header": "PromoBox-header-2BajU",
	"paragraph": "PromoBox-paragraph-2NIJp",
	"buttonContainer": "PromoBox-buttonContainer-2pBuw"
};
module.exports = exports;
