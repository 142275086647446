import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import c from 'classnames';
import { range } from 'lodash';
import moment from 'moment';
import Navigation from './Navigation';
import styles from './styles.m.less';
/**
 * Component that displays the calendar for a single month and navigation buttons to change the it.
 *
 * @prop {string} month Month to display. Format: YYYY-MM
 * @prop {string} from First day of the date range to display. Format: YYYY-MM-DD
 * @prop {string} to Last day of the date range to display. Format: YYYY-MM-DD
 * @prop {function} onDayClick Triggered when clicking on a day
 * @prop {function?} onDayMouseEnter Triggered when entering a day with the cursor
 * @prop {function?} onDayMouseLeave Triggered when leaving a day with the cursor
 * @prop {function?} onPrevMonthClick Triggered when clicking on the prev-month arrow. The arrow is hidden when the callback is not set
 * @prop {function?} onNextMonthClick Triggered when clicking on the next-month arrow. The arrow is hidden when the callback is not set
 * @prop {Array<string>} highlightedDates Dates that will be highlighted with a dot
 * @prop {Partial<DateRange>} validRange Dates outside of the validRange will be disabled
 * @prop {boolean} fixedHeight True if 6 weeks should always be displayed
 */
export default function Calendar(props) {
    return (_jsxs("div", { children: [_jsx(Navigation, { month: props.month, onPrevMonthClick: props.onPrevMonthClick, onNextMonthClick: props.onNextMonthClick }), _jsxs("div", { className: styles.table, children: [_jsx(WeekdayHeaders, {}), _jsx(DaysGrid, Object.assign({}, props))] })] }));
}
function WeekdayHeaders() {
    const monday = moment().startOf('week');
    return (_jsx("div", { className: styles.row, children: range(7).map(i => {
            const current = monday.clone().add(i, 'day');
            return (_jsx("div", { "aria-label": current.format('dddd'), className: c(styles.cell, styles.weekday), children: current.format('dd') }, i));
        }) }));
}
function DaysGrid(props) {
    const firstDay = moment(props.month).startOf('month').startOf('week');
    const weekCount = props.fixedHeight || firstDay.clone().add(5, 'weeks').isSameOrBefore(moment(props.month).endOf('month'))
        ? 6
        : 5;
    return (_jsx(_Fragment, { children: range(weekCount).map(week => (_jsx("div", { className: styles.row, children: range(7).map(day => {
                const currentDay = firstDay.clone().add(7 * week + day, 'day');
                return _jsx(Day, Object.assign({ currentDay: currentDay }, props), day);
            }) }, week))) }));
}
function Day({ currentDay, from, to, month, onDayMouseEnter = () => { }, onDayMouseLeave = () => { }, onDayClick, highlightedDates, validRange, }) {
    const isDateRangeSet = !!from && !!to;
    const isInRange = (inclusive) => isDateRangeSet && currentDay.isBetween(from, to, 'day', inclusive);
    const disabled = (!!validRange.from && currentDay.isBefore(validRange.from)) ||
        (!!validRange.to && currentDay.isAfter(validRange.to));
    return currentDay.isSame(month, 'month') ? (_jsxs("button", { type: "button", className: c(styles.cell, styles.outerDay, styles.interactive, {
            [styles.highlighted]: highlightedDates.some(date => currentDay.isSame(date)),
        }), onMouseEnter: disabled ? undefined : () => onDayMouseEnter(formatDate(currentDay)), onMouseLeave: disabled ? undefined : () => onDayMouseLeave(formatDate(currentDay)), onClick: () => onDayClick(formatDate(currentDay)), "aria-label": currentDay.format('LL'), disabled: disabled, tabIndex: -1, children: [_jsx("div", { className: c(styles.day, styles.dayBackground, {
                    [styles.today]: currentDay.isSame(moment(), 'day'),
                    [styles.selected]: isInRange('[]'),
                    [styles.selectedFrom]: isDateRangeSet && currentDay.isSame(from, 'day') && !currentDay.isSame(to, 'day'),
                    [styles.selectedTo]: isDateRangeSet && !currentDay.isSame(from, 'day') && currentDay.isSame(to, 'day'),
                    [styles.selectedBetween]: isInRange('()'),
                }) }), _jsx("div", { className: c(styles.day, styles.dayText, {
                    [styles.selectedText]: isInRange('[]'),
                    [styles.disabledText]: disabled,
                }), children: currentDay.date() })] })) : (_jsx("div", { className: c(styles.cell, styles.outerDay), "aria-label": currentDay.format('LL') }));
}
function formatDate(date) {
    return date.format(moment.HTML5_FMT.DATE);
}
