import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconX } from 'featherico';
import { componentWithClassName } from '../../services/Component';
import Button from '../Button';
import DeprecatedButtonContainer from '../ButtonContainer';
import { Card } from '../Cards';
import { TodoItemsGroup, TodoItemsGroupHeading } from '../TodoItems/TodoItemsGroup';
import styles from './style.m.less';
export const Header = componentWithClassName('div', styles.header);
export const Heading = componentWithClassName('h1', styles.heading);
export const Paragraph = componentWithClassName('p', styles.paragraph);
export const CardBox = ({ children }) => _jsx(Card, { className: styles.box, children: children });
export const CtaContainer = componentWithClassName(DeprecatedButtonContainer, styles.buttonContainer);
export const CloseButton = ({ onClick }) => (_jsx(Button, { kind: "PLAIN", onClick: onClick, children: _jsx(IconX, {}) }));
export const Container = ({ children, aboveText }) => (_jsxs(TodoItemsGroup, { children: [_jsx(TodoItemsGroupHeading, { children: aboveText }), _jsx(CardBox, { children: children })] }));
