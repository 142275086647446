// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".presenter-filesize-2WgpF {\n  color: #7a7a79;\n  font-size: 13px;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"filesize": "presenter-filesize-2WgpF"
};
module.exports = exports;
