// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".emojis-char-jHQ1W {\n  min-width: 20px;\n  display: inline-block;\n}\n.emojis-name-3WIxs {\n  font-weight: 400;\n  --fi-stroke: var(--fi-stroke-s);\n}\n", ""]);
// Exports
exports.locals = {
	"char": "emojis-char-jHQ1W",
	"name": "emojis-name-3WIxs"
};
module.exports = exports;
