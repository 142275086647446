// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Checkbox-description-3Ws9t {\n  color: #a3a2a1;\n  margin: -10px 0 10px;\n  padding-left: 1.5em;\n}\n.Checkbox-pending-2kjyv:before {\n  visibility: hidden;\n}\n.Checkbox-pendingSpinner-2Y8Au {\n  position: absolute;\n  left: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"description": "Checkbox-description-3Ws9t",
	"pending": "Checkbox-pending-2kjyv",
	"pendingSpinner": "Checkbox-pendingSpinner-2Y8Au"
};
module.exports = exports;
