import { Component } from 'react';
import { block } from '../../../routing/blocker';
export { default as GlobalInterceptorModal } from './GlobalInterceptorModal';
export default class Interceptor extends Component {
    constructor() {
        super(...arguments);
        this.unblock = null;
    }
    componentDidMount() {
        this.unblock = block((currentLocation, newLocation) => {
            const shouldIntercept = typeof this.props.if === 'function' ? this.props.if(currentLocation, newLocation) : this.props.if;
            return shouldIntercept ? this.props.message || true : false;
        });
    }
    componentWillUnmount() {
        this.unblock();
        this.unblock = null;
    }
    render() {
        return null;
    }
}
