import { jsx as _jsx } from "react/jsx-runtime";
import cN from 'classnames';
import * as React from 'react';
import styles from './style.m.less';
export function TodoItemsGroup({ children }) {
    return _jsx("div", { className: styles['todo-items-group'], children: children });
}
export function TodoItemsGroupHeading({ children }) {
    return _jsx("h3", { className: cN(styles['todo-items-group-days-until-title'], 'cards_section-heading'), children: children });
}
