// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dropdown-activeOption-rhBPo {\n  background: #edeceb;\n  text-decoration: none;\n}\n.Dropdown-divider-kg5Jl + .Dropdown-divider-kg5Jl,\n.Dropdown-divider-kg5Jl:first-child,\n.Dropdown-divider-kg5Jl:last-child {\n  display: none;\n}\n", ""]);
// Exports
exports.locals = {
	"activeOption": "Dropdown-activeOption-rhBPo",
	"divider": "Dropdown-divider-kg5Jl"
};
module.exports = exports;
