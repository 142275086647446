import { findKey } from 'lodash';
export const cond = (conditions, otherwise) => data => ({
    type: evaluateConditions(conditions, otherwise, data),
    data,
});
function evaluateConditions(conditions, otherwise, data) {
    const firstPassingCondition = findKey(conditions, condition => {
        return condition(data);
    });
    return firstPassingCondition || otherwise;
}
