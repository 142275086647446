import { jsx as _jsx } from "react/jsx-runtime";
import { debounce, delay } from 'lodash/fp';
import { Component, memo } from 'react';
import { FormSpy } from 'react-final-form';
const INITIAL_BACKOFF_MS = 100;
const promiseDelay = (delayMs) => new Promise(delay(delayMs));
class AutoSaver extends Component {
    constructor() {
        super(...arguments);
        this.backoff = INITIAL_BACKOFF_MS;
        this.promise = Promise.resolve();
        this.componentDidUpdate = (prevProps) => {
            const { values, submitFailed } = this.props;
            const formChanged = prevProps.values !== values;
            if (formChanged || submitFailed) {
                this.save(formChanged);
            }
        };
        this.save = debounce(this.props.debounceTime, async (formChanged) => {
            await this.promise;
            const { form, dirty, dirtySinceLastSubmit, submitFailed, submitSucceeded, keepDirtyOnReinitialize } = this.props;
            if (formChanged) {
                this.backoff = INITIAL_BACKOFF_MS;
            }
            if (submitFailed) {
                // Staggered retry.
                this.promise = promiseDelay(this.backoff).then(form.submit);
                this.backoff = this.backoff * 2;
                return;
            }
            const hasUnsavedChanges = keepDirtyOnReinitialize ? (!submitSucceeded && dirty) || dirtySinceLastSubmit : dirty;
            this.promise = hasUnsavedChanges ? form.submit() : Promise.resolve();
        });
        this.render = () => null;
    }
}
const FormAutoSave = memo(({ keepDirtyOnReinitialize, debounceTime = 1000 }) => (_jsx(FormSpy, { subscription: {
        dirty: true,
        dirtySinceLastSubmit: true,
        submitFailed: true,
        submitSucceeded: true,
        values: true,
    }, component: renderProps => (_jsx(AutoSaver, Object.assign({}, renderProps, { keepDirtyOnReinitialize: keepDirtyOnReinitialize, debounceTime: debounceTime }))) })));
export default FormAutoSave;
