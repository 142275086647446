import api from '../../../../api';
export function getSpark(storyId, typeId, visibility) {
    return api.sparks.getOne(storyId, typeId, visibility);
}
export function dismiss(story, type) {
    return api.sparks.dismiss({ story, type });
}
export function visit(story, type, visibility) {
    return api.sparks.visit({ story, type, visibility });
}
export function isSparkInTimeRange({ startDate = -Infinity, endDate = Infinity }, date) {
    return startDate < date && date < endDate;
}
