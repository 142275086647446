// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextareaAutosize-textareaAutosize-3wlYQ {\n  width: 100%;\n  border: 1px solid #e0dfde;\n  resize: none;\n}\n.TextareaAutosize-invalid-2PDsQ,\n.TextareaAutosize-invalid-2PDsQ:hover {\n  border-color: #f23037;\n}\n.TextareaAutosize-invalid-2PDsQ:active,\n.TextareaAutosize-invalid-2PDsQ:focus {\n  border-color: #f23037;\n  box-shadow: 0 0 0 1px #f23037;\n}\n", ""]);
// Exports
exports.locals = {
	"textareaAutosize": "TextareaAutosize-textareaAutosize-3wlYQ",
	"invalid": "TextareaAutosize-invalid-2PDsQ"
};
module.exports = exports;
