export default {
    SUBJECT_PRONOUN: 'sheHe',
    OBJECT_PRONOUN: 'herHim',
    POSSESSIVE_PRONOUN: 'herHis',
    REFLEXIVE_PRONOUN: 'herselfHimself',
    SUBJECT_PRONOUN_REVERSE: 'heShe',
    OBJECT_PRONOUN_REVERSE: 'himHer',
    POSSESSIVE_PRONOUN_REVERSE: 'hisHer',
    REFLEXIVE_PRONOUN_REVERSE: 'himselfHerself',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    FULL_NAME: 'fullName',
};
