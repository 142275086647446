import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.m.less';
ImageWithAspectRatio.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
};
export default function ImageWithAspectRatio({ src, width, height, className }) {
    const paddingBottom = 100 / (width / height) + '%';
    const style = {
        backgroundImage: `url('${src}')`,
        paddingBottom,
    };
    const props = {
        style,
        className: classNames(styles.image, className),
    };
    return _jsx("div", Object.assign({}, props));
}
