import { useEffect, useRef, useState } from 'react';
export const useIsVisible = (threshold) => {
    const [isInViewport, setIsInViewport] = useState(false);
    const [isInForeground, setIsInForeground] = useState(document.visibilityState === 'visible');
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current && typeof IntersectionObserver !== 'undefined') {
            // eslint-disable-next-line no-undef
            const observer = new IntersectionObserver((entries) => {
                const visibleNow = entries.some(entry => entry.isIntersecting);
                setIsInViewport(visibleNow);
            }, { threshold });
            observer.observe(ref.current);
            return () => observer.disconnect();
        }
    }, [threshold]);
    useEffect(() => {
        const handler = () => {
            setIsInForeground(document.visibilityState === 'visible');
        };
        document.addEventListener('visibilitychange', handler);
        return () => document.removeEventListener('visibilitychange', handler);
    }, []);
    return { isVisible: isInViewport && isInForeground, ref };
};
