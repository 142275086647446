export default function prettyFilesize(fileSizeInBytes) {
    let factor, unit;
    if (fileSizeInBytes >= 1000000000) {
        factor = 0.000000001;
        unit = 'GB';
    }
    else if (fileSizeInBytes >= 1000000) {
        factor = 0.000001;
        unit = 'MB';
    }
    else if (fileSizeInBytes >= 1000) {
        factor = 0.001;
        unit = 'KB';
    }
    else if (fileSizeInBytes < 1000) {
        return '1.0 KB';
    }
    const s = factor * fileSizeInBytes;
    return `${s.toFixed(1)} ${unit}`;
}
