import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { getCurrentUser } from '../../../services/CurrentUser';
import AddUpgradeIcon from '../../AddUpgradeIcon';
import Modal from '../../Modal';
import UpgradePlanContent from './UpgradePlanContent';
import styles from './styles.m.less';
const ButtonWithUpgradePlanModal = ({ TriggerButton, disabled, buttonProps, showUpgradeIcon = true, }) => {
    const [showModal, setShowModal] = useState(false);
    const currentUser = getCurrentUser();
    const customerPlan = currentUser.company.payment.plan;
    const handleButtonClick = () => {
        if (disabled) {
            setShowModal(prev => !prev);
        }
    };
    const modalContent = (_jsx(Modal, { isOpen: showModal, className: styles.upgradeModal, onClose: () => setShowModal(false), children: _jsx(UpgradePlanContent, { currentPlan: customerPlan }) }));
    const buttonContent = (_jsx(_Fragment, { children: showUpgradeIcon ? (_jsx(AddUpgradeIcon, { children: _jsx(TriggerButton, Object.assign({}, buttonProps, { disabled: disabled })) })) : (_jsx(TriggerButton, Object.assign({}, buttonProps, { disabled: disabled }))) }));
    return disabled ? (_jsxs("div", { role: "button", onClick: handleButtonClick, className: styles.upgradeButtonContainer, style: { cursor: 'pointer' }, children: [showModal && modalContent, buttonContent] })) : (_jsx(TriggerButton, Object.assign({}, buttonProps, { disabled: disabled })));
};
export default ButtonWithUpgradePlanModal;
