import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { DefaultStyle, PlainCardStyle, PraiseStyle, TodoStyle } from './EntityStyles';
const entityShapes = {
    TODO: 'TODO',
    PLAIN_CARD: 'PLAIN_CARD',
    PRAISE: 'PRAISE',
};
LoadingContentIndicator.propTypes = {
    entityShape: PropTypes.oneOf(Object.keys(entityShapes)),
};
export default function LoadingContentIndicator({ entityShape }) {
    if (entityShape === 'TODO') {
        return _jsx(TodoStyle, {});
    }
    if (entityShape === 'PLAIN_CARD') {
        return _jsx(PlainCardStyle, {});
    }
    if (entityShape === entityShapes.PRAISE) {
        return _jsx(PraiseStyle, {});
    }
    return _jsx(DefaultStyle, {});
}
