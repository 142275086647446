// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonWithUpgradePlanModal-upgradeButtonContainer-3U2hC {\n  cursor: pointer;\n  position: relative;\n}\n.ButtonWithUpgradePlanModal-upgradeButtonContainer-3U2hC button {\n  pointer-events: auto;\n  color: #a3a2a1;\n}\n.ButtonWithUpgradePlanModal-upgradeModal-18ZzP {\n  display: flex;\n}\n", ""]);
// Exports
exports.locals = {
	"upgradeButtonContainer": "ButtonWithUpgradePlanModal-upgradeButtonContainer-3U2hC",
	"upgradeModal": "ButtonWithUpgradePlanModal-upgradeModal-18ZzP"
};
module.exports = exports;
